import React, { useCallback } from 'react'

import { MiddleTruncate } from '@re-dev/react-truncate'

import { useUrlAttributeDisplayState } from 'features/views/attributes/hooks/useUrlAttributeDisplayState'

import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Tooltip } from 'ui/components/Tooltip'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useUrlAttributeEditorState } from './hooks/useUrlAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type UrlAttributeProps = {}

export const UrlAttribute: FieldsWidgetAttributeComponent<UrlAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue } = useAttributeContext<string>()

    const isEmpty = !isLoading && !value

    const { target, to, href, label } = useUrlAttributeDisplayState({
        field: field.field,
        value,
        isLoading,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    if (isEditingValue) {
        return (
            <UrlAttributeEditor
                key="url-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="url-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            {(!!href || !!to) && (
                <Skeleton isLoading={isLoading} width="full">
                    <Tooltip
                        asChild
                        content={href || to || label}
                        zIndex={200}
                        side="bottom"
                        align="start"
                    >
                        <Link
                            href={href}
                            to={to}
                            size="m"
                            target={target}
                            onClick={handleClick}
                            tabIndex={0}
                            width="full"
                        >
                            <MiddleTruncate end={7}>{label}</MiddleTruncate>
                        </Link>
                    </Tooltip>
                </Skeleton>
            )}
        </BaseAttribute>
    )
}

const UrlAttributeEditor: FieldsWidgetAttributeComponent<UrlAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue } =
        useUrlAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
            />
        </BaseAttribute>
    )
}
