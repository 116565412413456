import { useCallback, useMemo, useRef, useState } from 'react'

import { FieldsWidgetFieldValueControlsStyle } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import { useAttributeContext } from './useAttributeContext'

const NUMBER_REGEXP = /^-?\d*\.?\d*$/

export function usePercentageAttributeEditorState() {
    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
    } = useAttributeContext<number>()

    let valueFromAttribute: string
    if (typeof attributeValue === 'number') {
        valueFromAttribute = (attributeValue * 100.0).toString()
    } else {
        valueFromAttribute = attributeValue ?? ''
    }

    const [value, setValue] = useState(valueFromAttribute)
    const valueRef = useRef(value)
    valueRef.current = value

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const isValid = NUMBER_REGEXP.test(value)
        if (!isValid) return

        setValue(value)
    }, [])

    const onInputBlur = useCallback(
        (e?: React.FocusEvent<HTMLInputElement>) => {
            // If the blur event is triggered by the field value controls, we don't want to save the value.
            const targetEl = e?.relatedTarget as HTMLElement | null
            if (targetEl?.closest(`.${FieldsWidgetFieldValueControlsStyle}`)) {
                return
            }

            queueMicrotask(() => {
                let targetValue: number | null = parseFloat(valueRef.current) / 100.0
                targetValue = Number.isNaN(targetValue) ? null : targetValue

                replaceValue(targetValue as any)
                saveValue()
            })
        },
        [replaceValue, saveValue]
    )

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                onInputBlur()
            }

            if (e.key === 'Escape') {
                e.preventDefault()
                discardChanges()
            }
        },
        [discardChanges, onInputBlur]
    )

    const onClearValue = useCallback(() => {
        setValue('')
        clearValue()
    }, [clearValue])

    return useMemo(
        () => ({
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            value,
            onClearValue,
        }),
        [onInputChange, onInputBlur, onInputKeyDown, value, onClearValue]
    )
}
