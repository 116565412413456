import { LayoutEditorSchema } from 'features/views/LayoutEditor/types'
import { FeedWidgetMeta } from 'features/views/LayoutEditor/widgets/FeedWidgetMeta'
import { FieldsWidgetMeta } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidgetMeta'
import { ListViewWidgetMeta } from 'features/views/LayoutEditor/widgets/ListViewWidget/ListViewWidgetMeta'
import { SectionWidgetMeta } from 'features/views/LayoutEditor/widgets/SectionWidgetMeta'
import { TabsWidgetMeta } from 'features/views/LayoutEditor/widgets/TabsWidgetMeta'
import { TextWidgetMeta } from 'features/views/LayoutEditor/widgets/TextWidgetMeta'

export async function computeDetailViewSchema(): Promise<LayoutEditorSchema> {
    return {
        version: 1,
        widgets: {
            text: TextWidgetMeta,
            section: SectionWidgetMeta,
            fields: FieldsWidgetMeta,
            tabs: TabsWidgetMeta,
            listView: ListViewWidgetMeta,
            feed: FeedWidgetMeta,
        },
    }
}
