import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { SectionWidget, SectionWidgetAdminControls, SectionWidgetType } from './SectionWidget'

export const SectionWidgetMeta: WidgetSchema<SectionWidgetType> = {
    category: 'hidden',
    label: 'Section',
    icon: 'LayoutTemplate',
    component: SectionWidget,
    adminControlsComponent: SectionWidgetAdminControls,
    isContainer: true,
    defaultAttrs: {
        numOfColumns: 2,
    },
}
