import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'
import { LongTextAttributeTag } from 'features/views/attributes/LongTextAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_LINES = 1
const MAX_LINES_TITLE = 3
const MAX_LINES_SUBTITLE = 2
const MAX_LENGTH = 40
const MAX_LENGTH_TITLE = 120
const MAX_LENGTH_SUBTITLE = 120
const MAX_OVERFLOW_LENGTH = 300

type LongTextAttributeProps = {}

export const LongTextAttribute: BoardViewAttributeComponent<string, LongTextAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const { field, type } = attribute
    const isTitle = type === 'title'
    const isFooter = type === 'footer'
    const isSubtitle = type === 'subtitle'

    const isEmpty = !isLoading && !value

    let maxLines = MAX_LINES
    if (isTitle) {
        maxLines = MAX_LINES_TITLE
    } else if (isSubtitle) {
        maxLines = MAX_LINES_SUBTITLE
    }

    let maxLength = MAX_LENGTH
    if (isTitle) {
        maxLength = MAX_LENGTH_TITLE
    } else if (isSubtitle) {
        maxLength = MAX_LENGTH_SUBTITLE
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} {...props}>
            {displayStyle === 'tags' || isFooter ? (
                <LongTextAttributeTag
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    size={isFooter ? 'xs' : '2xs'}
                    showIcon={showIcon}
                />
            ) : (
                <LongTextAttributeDisplay
                    value={value}
                    style={{
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        letterSpacing: 'inherit',
                        fontWeight: 'inherit',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap',
                    }}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    field={field}
                    maxLines={maxLines}
                    maxLength={maxLength}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    trim={!isTitle && !isSubtitle}
                />
            )}
        </BaseAttribute>
    )
}
