import React from 'react'

import { UserAttributeDisplay } from 'features/views/attributes/UserAttributeDisplay'

import { useAttributeContext } from './hooks/useAttributeContext'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type UserAttributeProps = {}

export const UserAttribute: FieldsWidgetAttributeComponent<UserAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value } = useAttributeContext<UserRefDto>()
    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <UserAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                size="m"
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                maxWidth="full"
                minWidth={0}
                trim
            />
        </BaseAttribute>
    )
}
