import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon/Icon'

import { TRIGGER_TYPES } from './constants'

export function TriggerAvatar({ triggerType }: { triggerType: string }) {
    const config = TRIGGER_TYPES[triggerType]

    const color = config.color + '500'

    return (
        <Box
            flex
            center
            // @ts-ignore
            background={color}
            borderRadius="m"
            height="4xl"
            width="4xl"
            color="lightAlpha900"
            justifyContent="center"
            noShrink
        >
            <Icon name={config.icon} size="xl" />
        </Box>
    )
}
