// @ts-strict-ignore
import React, { useImperativeHandle } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Dropdown,
    DropdownContent,
    DropdownEndIndicatorShortcut,
    DropdownItem,
    DropdownSeparator,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useLayoutEditorDnDWidgetState } from './hooks/useLayoutEditorDnDWidgetState'
import {
    LayoutEditorWidgetContextMenuHandle,
    useLayoutEditorWidgetContextMenuState,
} from './hooks/useLayoutEditorWidgetContextMenuState'
import { useLayoutEditorWidgetState } from './hooks/useLayoutEditorWidgetState'
import { LayoutEditorWidgetContextProvider } from './LayoutEditorWidgetContext'
import { LayoutEditorWidgetErrorBoundary } from './LayoutEditorWidgetErrorBoundary'
import { Widget } from './types'

import { LayoutEditorWidgetControlsStyles, LayoutEditorWidgetStyles } from './LayoutEditor.css'

type LayoutEditorWidgetRef = HTMLDivElement

type LayoutEditorWidgetProps = React.ComponentPropsWithoutRef<typeof Box> & {
    widget: Widget
    isDragging?: boolean
}

/**
 * This component is responsible for rendering widgets and their admin controls in the canvas.
 */
const LayoutEditorWidgetInner = React.forwardRef<LayoutEditorWidgetRef, LayoutEditorWidgetProps>(
    function LayoutEditorWidget({ widget, isDragging, ...props }, ref) {
        const {
            Component,
            isSelected,
            onClick,
            isSelectable,
            widgetLabel,
            isEditing,
            isDraggingAnotherWidget,
            onContextMenu,
            contextMenuRef,
            onRemoveWidget,
            onDuplicateWidget,
            onCopyWidget,
            onPasteWidget,
            isContainer,
            widgetRef,
        } = useLayoutEditorWidgetState({
            widget,
        })

        const composedRef = useComposedRefs(ref, widgetRef)

        if (!Component) return null

        return (
            <ThemeProvider theme="admin">
                <LayoutEditorWidgetContextProvider widget={widget} isDragging={isDragging}>
                    <Box
                        ref={composedRef}
                        className={LayoutEditorWidgetStyles.styleFunction({
                            isSelected,
                            isSelectable,
                            isDragging,
                            isDraggingAnotherWidget,
                            isContainer,
                        })}
                        onClick={onClick}
                        onContextMenu={onContextMenu}
                        tabIndex={isSelectable ? 0 : undefined}
                        mb="l"
                        {...props}
                    >
                        <LayoutEditorWidgetErrorBoundary>
                            <ThemeProvider theme="default">
                                <Component
                                    widget={widget}
                                    isEditing={isEditing}
                                    isSelected={isSelected}
                                />
                            </ThemeProvider>
                        </LayoutEditorWidgetErrorBoundary>
                        {isSelected && !isDragging && (
                            <WidgetControls
                                isContainer={isContainer}
                                widgetLabel={widgetLabel}
                                onRemoveWidget={onRemoveWidget}
                            />
                        )}
                    </Box>
                    <WidgetContextMenu
                        ref={contextMenuRef}
                        onRemoveWidget={onRemoveWidget}
                        onDuplicateWidget={onDuplicateWidget}
                        onCopyWidget={onCopyWidget}
                        onPasteWidget={onPasteWidget}
                    />
                </LayoutEditorWidgetContextProvider>
            </ThemeProvider>
        )
    }
)

export const LayoutEditorWidget = React.memo(LayoutEditorWidgetInner)

type WidgetControlsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    widgetLabel: string
    isContainer?: boolean
    onRemoveWidget?: () => void
}

const WidgetControls: React.FC<WidgetControlsProps> = ({
    widgetLabel,
    isContainer,
    onRemoveWidget,
    ...props
}) => {
    return (
        <Box className={LayoutEditorWidgetControlsStyles.styleFunction({ isContainer })} {...props}>
            <Box
                background="theme600"
                flex
                center
                roundedTopLeft={isContainer ? 's' : undefined}
                roundedTopRight={isContainer ? 's' : undefined}
                roundedBottomLeft={isContainer ? undefined : 's'}
                height="full"
            >
                {isContainer && (
                    <Box px="s">
                        <Body size="s" color="textInverse" weight="medium">
                            {widgetLabel}
                        </Body>
                    </Box>
                )}
                <Box flex center>
                    <Button
                        variant="ghost"
                        size="2xs"
                        startIcon={{ name: 'X' }}
                        onClick={onRemoveWidget}
                    />
                </Box>
            </Box>
        </Box>
    )
}

type LayoutEditorDnDWidgetProps = LayoutEditorWidgetProps & {}

export const LayoutEditorDnDWidget: React.FC<LayoutEditorDnDWidgetProps> = React.memo(
    function LayoutEditorDnDWidget(props) {
        const { attributes, listeners, setNodeRef, isDragging } = useLayoutEditorDnDWidgetState({
            widgetId: props.widget.id,
            widgetType: props.widget.type,
        })

        return (
            <LayoutEditorWidget
                ref={setNodeRef}
                isDragging={isDragging}
                {...attributes}
                {...listeners}
                {...props}
            />
        )
    }
)

type WidgetContextMenuProps = {
    onRemoveWidget: () => void
    onDuplicateWidget: () => void
    onCopyWidget: () => void
    onPasteWidget: () => void
}

const WidgetContextMenu = React.forwardRef<
    LayoutEditorWidgetContextMenuHandle,
    WidgetContextMenuProps
>(function WidgetContextMenu(
    { onRemoveWidget, onDuplicateWidget, onCopyWidget, onPasteWidget },
    ref
) {
    const { openAt, close, isOpen, setIsOpen, posX, posY } = useLayoutEditorWidgetContextMenuState()

    useImperativeHandle(
        ref,
        () => ({
            openAt,
            close,
        }),
        [close, openAt]
    )

    if (!isOpen) return null

    return (
        <Dropdown open={isOpen} onOpenChange={setIsOpen}>
            <DropdownTrigger
                ref={(el) => {
                    if (!el) return

                    // This is a hack to make the dropdown trigger behave like a fixed element,
                    // even if the parent has transform set.
                    el.getBoundingClientRect = () => {
                        return {
                            width: 0,
                            height: 0,
                            x: posX,
                            left: posX,
                            bottom: posY,
                            y: posY,
                            right: posX,
                            top: posY,
                        } as DOMRect
                    }
                }}
                position="fixed"
                pointerEvents="none"
                display="none"
            />
            <DropdownContent
                onCloseAutoFocus={stopPropagation}
                onClick={stopPropagation}
                style={{
                    minWidth: '186px',
                }}
            >
                <DropdownItem
                    label="Duplicate"
                    onClick={onDuplicateWidget}
                    rightSlotContent={(slotProps) => (
                        <DropdownEndIndicatorShortcut
                            label={{
                                mac: '⌘D',
                                default: 'Ctrl + D',
                            }}
                            {...slotProps}
                        />
                    )}
                />
                <DropdownSeparator />
                <DropdownItem
                    label="Copy"
                    onClick={onCopyWidget}
                    rightSlotContent={(slotProps) => (
                        <DropdownEndIndicatorShortcut
                            label={{
                                mac: '⌘C',
                                default: 'Ctrl + C',
                            }}
                            {...slotProps}
                        />
                    )}
                />
                <DropdownItem
                    label="Paste"
                    onClick={onPasteWidget}
                    rightSlotContent={(slotProps) => (
                        <DropdownEndIndicatorShortcut
                            label={{
                                mac: '⌘V',
                                default: 'Ctrl + V',
                            }}
                            {...slotProps}
                        />
                    )}
                />
                <DropdownSeparator />
                <DropdownItem
                    label="Delete"
                    variant="destructive"
                    onClick={onRemoveWidget}
                    rightSlotContent={(slotProps) => (
                        <DropdownEndIndicatorShortcut
                            label={{
                                mac: '⌦',
                                default: 'Del',
                            }}
                            {...slotProps}
                        />
                    )}
                />
            </DropdownContent>
        </Dropdown>
    )
})
