import React from 'react'

import { withDataConnections } from 'data/wrappers/withDataConnections'
import { WithObjects } from 'data/wrappers/WithObjectsComponent'

import { Box } from 'ui/components/Box'
import { Select, SelectOption, SelectTitle } from 'ui/components/Select'

type DataConnectionDto = {
    _sid: string
    label: string
}

type ObjectDto = {
    _sid: string
    name: string
    data_connection: string
    connection_options: {
        data_mapping_disabled: boolean
    }
}

type ObjectOption = {
    label: string
    value: string
    object: ObjectDto
}

type InnerObjectPickerProps = {
    id?: string
    showFeatureConfigData?: boolean
    onChange: (value: any) => void
    placeholder?: string
    value?: any
    style?: React.CSSProperties
    returnObject?: boolean
    objectSidWhitelist?: string[]
    filter?: (obj: ObjectDto) => boolean
    dataConnections?: DataConnectionDto[]
}

const InnerObjectPicker: React.FC<InnerObjectPickerProps> = ({
    id,
    onChange,
    placeholder = 'Select data source',
    value,
    style,
    objectSidWhitelist,
    filter,
    dataConnections = [],
    ...props
}) => {
    return (
        <WithObjects>
            {({ objects = [] }) => {
                const isGrouped = dataConnections?.length > 1

                if (isGrouped) {
                    return (
                        <Box style={{ ...style }}>
                            <Select
                                id={id}
                                onChange={onChange}
                                placeholder={placeholder}
                                value={value}
                                {...props}
                            >
                                {dataConnections.map((dc) => (
                                    <React.Fragment key={dc._sid}>
                                        <SelectTitle>{dc.label}</SelectTitle>
                                        {objectToOptions(
                                            objects,
                                            objectSidWhitelist,
                                            filter,
                                            dc._sid
                                        ).map((option) => (
                                            <SelectOption
                                                key={option.value}
                                                value={option.value}
                                                label={option.label}
                                            />
                                        ))}
                                    </React.Fragment>
                                ))}
                            </Select>
                        </Box>
                    )
                }

                return (
                    <Box style={{ ...style }}>
                        <Select
                            id={id}
                            onChange={onChange}
                            placeholder={placeholder}
                            value={value}
                            {...props}
                        >
                            {objectToOptions(objects, objectSidWhitelist, filter).map((option) => (
                                <SelectOption
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                />
                            ))}
                        </Select>
                    </Box>
                )
            }}
        </WithObjects>
    )
}

const objectToOptions = (
    objects: ObjectDto[],
    sidWhitelist?: string[],
    filter?: (obj: ObjectDto) => boolean,
    dataConnectionId?: string
): ObjectOption[] => {
    return objects
        .filter(
            (obj) =>
                (!filter || filter(obj)) &&
                (!sidWhitelist || sidWhitelist.includes(obj._sid)) &&
                (!dataConnectionId || obj.data_connection === dataConnectionId)
        )
        .reduce<ObjectOption[]>((filtered, obj) => {
            if (!obj.connection_options.data_mapping_disabled) {
                filtered.push({
                    label: obj.name,
                    value: obj._sid,
                    object: obj,
                })
            }
            return filtered
        }, [])
}

export const ObjectPicker = withDataConnections(InnerObjectPicker)
