import * as Y from 'yjs'

import { DetailViewHeader, DetailViewLayout } from 'features/views/DetailView/types'
import { getYLayout } from 'features/views/LayoutEditor/utils'

import { getSupportedTitleFields } from './headers/utils'

export function getDetailViewHeader(
    layout?: DetailViewLayout,
    fields?: FieldDto[]
): DetailViewHeader {
    const supportedTitleFields = getSupportedTitleFields(fields ?? [])
    const defaultTitleField = supportedTitleFields[0]

    const defaultHeader: DetailViewHeader = {
        type: 'slim',
        title: !!defaultTitleField
            ? {
                  fieldApiName: defaultTitleField.api_name,
              }
            : undefined,
    }

    return {
        ...defaultHeader,
        ...layout?.header,
    }
}

export function getYHeader(view?: Y.Map<any>): Y.Map<any> | undefined {
    if (!view) return

    const layout = getYLayout(view)

    let header = layout.get('header')
    if (!header) {
        header = layout.set('header', new Y.Map())
    }

    return header
}

export function getYActions(view?: Y.Map<any>): Y.Map<any> | undefined {
    if (!view) return

    const layout = getYLayout(view)

    let actions = layout.get('actions')
    if (!actions) {
        actions = layout.set('actions', new Y.Map())
    }

    return actions
}

export function getDetailViewRequiredFields(view?: ViewDto, fields?: FieldDto[]): string[] {
    if (!view) return []

    const fieldsSet = new Set<string>()

    const titleField = view.layout?.header?.title?.fieldApiName
    if (!!titleField) {
        fieldsSet.add(titleField)
    }

    const profileField = view.layout?.header?.profileImage?.fieldApiName
    if (!!profileField) {
        fieldsSet.add(profileField)
    }

    const eyebrowField = view.layout?.header?.eyebrow?.fieldApiName
    if (!!eyebrowField) {
        fieldsSet.add(eyebrowField)
    }

    // Handle action buttons.
    const actionButtons = view.layout?.actions?.pageButtons
    if (!!actionButtons) {
        const allFieldsBySid = fields?.reduce((acc, field) => {
            acc.set(field._sid, field)
            return acc
        }, new Map<string, FieldDto>())

        for (const button of actionButtons) {
            for (const condition of button.conditions) {
                if (!!condition.field_sid) {
                    const field = allFieldsBySid?.get(condition.field_sid)
                    if (!!field) {
                        fieldsSet.add(field.api_name)
                    }
                }
            }
        }
    }

    return Array.from(fieldsSet)
}
