import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FieldsWidgetFieldValueControlsStyle } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'
import { isCmdOrCtrlPressed } from 'utils/isCmdOrCtrlPressed'

import { useAttributeContext } from './useAttributeContext'

export function useLongTextAttributeEditorState() {
    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
    } = useAttributeContext<string>()

    const [value, setValue] = useState(attributeValue ?? '')
    const valueRef = useRef(value)
    valueRef.current = value

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        setValue(value)
    }, [])

    const onInputBlur = useCallback(
        (e?: React.FocusEvent<HTMLTextAreaElement>) => {
            // If the blur event is triggered by the field value controls, we don't want to save the value.
            const targetEl = e?.relatedTarget as HTMLElement | null
            if (targetEl?.closest(`.${FieldsWidgetFieldValueControlsStyle}`)) {
                return
            }

            queueMicrotask(() => {
                replaceValue(valueRef.current as any)
                saveValue()
            })
        },
        [saveValue, replaceValue]
    )

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === 'Enter' && isCmdOrCtrlPressed(e.nativeEvent)) {
                e.preventDefault()
                onInputBlur()
            }

            if (e.key === 'Escape') {
                e.preventDefault()
                discardChanges()
            }
        },
        [discardChanges, onInputBlur]
    )

    const onClearValue = useCallback(() => {
        setValue('')
        clearValue()
    }, [clearValue])

    const editorRef = useRef<HTMLTextAreaElement>(null)
    useEffect(() => {
        editorRef.current?.focus()

        const value = editorRef.current?.value
        if (!!value) {
            editorRef.current?.setSelectionRange(value.length, value.length)
        }
    }, [editorRef])

    return useMemo(
        () => ({
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            value,
            onClearValue,
            editorRef,
        }),
        [onInputChange, onInputBlur, onInputKeyDown, value, onClearValue]
    )
}
