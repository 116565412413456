export const FETCH_STARTED = 'FETCH_STARTED'
export const FETCH_COMPLETED = 'FETCH_COMPLETED'
export const CLEAR_STORE = 'CLEAR_STORE'

export const ACTIONS_FETCHED = 'ACTIONS_FETCHED'
export const ACTION_CREATED = 'ACTION_CREATED'
export const ACTION_UPDATED = 'ACTION_UPDATED'
export const ACTION_REMOVED = 'ACTION_REMOVED'

export const APPS_FETCHED = 'APPS_FETCHED'
export const APP_CREATED = 'APP_CREATED'
export const APP_UPDATED = 'APP_UPDATED'
export const APP_REMOVED = 'APP_REMOVED'
export const APP_SELECTED = 'APP_SELECTED'

export const DATA_CONNECTIONS_FETCHED = 'DATA_CONNECTIONS_FETCHED'
export const DATA_CONNECTION_CREATED = 'DATA_CONNECTION_CREATED'
export const DATA_CONNECTION_UPDATED = 'DATA_CONNECTION_UPDATED'
export const DATA_CONNECTION_REMOVED = 'DATA_CONNECTION_REMOVED'

export const EMAIL_TEMPLATES_FETCHED = 'EMAIL_TEMPLATES_FETCHED'
export const EMAIL_TEMPLATE_CREATED = 'EMAIL_TEMPLATE_CREATED'
export const EMAIL_TEMPLATE_UPDATED = 'EMAIL_TEMPLATE_UPDATED'
export const EMAIL_TEMPLATE_REMOVED = 'EMAIL_TEMPLATE_REMOVED'

export const PERMISSION_RULE_FETCHED = 'PERMISSION_RULE_FETCHED'
export const PERMISSION_RULE_CREATED = 'PERMISSION_RULE_CREATED'
export const PERMISSION_RULE_UPDATED = 'PERMISSION_RULE_UPDATED'
export const PERMISSION_RULE_REMOVED = 'PERMISSION_RULE_REMOVED'

export const RECORD_REMOVED = 'RECORD_REMOVED'

export const AUTHS_FETCHED = 'AUTHS_FETCHED'
export const AUTH_CREATED = 'AUTH_CREATED'
export const AUTH_UPDATED = 'AUTH_UPDATED'
export const AUTH_REMOVED = 'AUTH_REMOVED'

export const TRANSLATIONS_FETCHED = 'TRANSLATIONS_FETCHED'
export const TRANSLATION_CREATED = 'TRANSLATION_CREATED'
export const TRANSLATION_UPDATED = 'TRANSLATION_UPDATED'
export const TRANSLATION_REMOVED = 'TRANSLATION_REMOVED'
export const ACCEPTED_TRANSLATIONS_FETCHED = 'ACCEPTED_TRANSLATIONS_FETCHED'

export const WORKFLOWS_FETCHED = 'WORKFLOWS_FETCHED'
export const WORKFLOW_CREATED = 'WORKFLOW_CREATED'
export const WORKFLOW_UPDATED = 'WORKFLOW_UPDATED'
export const WORKFLOW_REMOVED = 'WORKFLOW_REMOVED'

export const STACK_SELECTED = 'STACK_SELECTED'

export const EDITING_STARTED = 'EDITING_STARTED'
export const EDITING_STOPPED = 'EDITING_STOPPED'
export const EDITING_STOP_REQUESTED = 'EDITING_STOP_REQUESTED'
export const EDITING_START_REQUESTED = 'EDITING_START_REQUESTED'
export const DISCARD_STOP_EDITING_REQUEST = 'DISCARD_STOP_EDITING_REQUEST'

export const DISCARD_EDITING_REQUEST = 'DISCARD_EDITING_REQUEST'

export const SLIDING_PANE_CLOSE = 'SLIDING_PANE_CLOSE'
export const SLIDING_PANE_CLEAR_CONTENT = 'SLIDING_PANE_CLEAR_CONTENT'
export const SLIDING_PANE_OPEN = 'SLIDING_PANE_OPEN'
export const SLIDING_PANE_TRANSITION_TO_WIDTH = 'SLIDING_PANE_TRANSITION_TO_WIDTH'
export const SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO =
    'SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO'
export const SLIDING_PANE_SET_ANIMATION_COMPLETE = 'SLIDING_PANE_SET_ANIMATION_COMPLETE'
export const SLIDING_PANE_SET_CONTENT_DIRTY = 'SLIDING_PANE_SET_CONTENT_DIRTY'
export const SLIDING_PANE_UNSET_CONTENT_DIRTY = 'SLIDING_PANE_UNSET_CONTENT_DIRTY'
export const SLIDING_PANE_BLOCK_CONTENT = 'SLIDING_PANE_BLOCK_CONTENT'
export const SLIDING_PANE_CLEAR_BLOCKED_CONTENT = 'SLIDING_PANE_CLEAR_BLOCKED_CONTENT'
export const SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL = 'SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL'
export const SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL = 'SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL'
export const SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK = 'SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK'

export const REACT_QUERY: {
    [key: string]: { listName: REACT_QUERY_DEPS_NAME; endpoint: string }
} = {
    roles: {
        listName: 'useAccountRoles',
        endpoint: 'roles/',
    },
}

export const DATA_CONNECTION_TYPE_VALUES = [
    'native_tables',
    'nango_airtable',
    'nango_hubspot',
] as const
