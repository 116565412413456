import React, { useState } from 'react'

import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'

import { SaveAndDiscardFormButtons } from '../common/SaveAndDiscardFormButtons'

import { EditAgentForm } from './EditAgentForm'
import { EditAgentFormWrapper } from './EditAgentFormWrapper'

type EditAgentGeneralProps = {
    agent: Agent
}

export const EditAgentGeneral: React.FC<EditAgentGeneralProps> = ({ agent }) => {
    const [error, setError] = useState<string>('')

    return (
        <Box flex column width="full">
            <EditAgentFormWrapper
                agent={agent}
                onSuccess={() => {
                    // Handle success if needed
                }}
                setError={setError}
            >
                <EditAgentForm showAllOptions />

                <SaveAndDiscardFormButtons error={error} />
            </EditAgentFormWrapper>
        </Box>
    )
}
