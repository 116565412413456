import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentValue } from 'features/views/attributes/types'

import ReactFilestack from 'v2/ui/ReactFilestack'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { useAttachmentsAttributeEditorState } from './hooks/useAttachmentsAttributeEditorState'
import { useAttributeContext } from './hooks/useAttributeContext'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

import { AttachmentsAttributeStyle } from './AttachmentsAttribute.css'

const MAX_ATTACHMENTS = 2

type AttachmentsAttributeProps = {}

export const AttachmentsAttribute: FieldsWidgetAttributeComponent<AttachmentsAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, labelPlacement, isEditingValue } = useAttributeContext<AttachmentValue[]>()
    const isEmpty = !isLoading && (!value || value.length < 1)

    if (isEditingValue) {
        return (
            <AttachmentsAttributeEditor
                key="attachments-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            labelPlacement={labelPlacement}
            isLoading={isLoading}
            alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
            className={AttachmentsAttributeStyle}
        >
            <AttachmentsAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxAttachments={MAX_ATTACHMENTS}
                size="m"
                maxWidth="full"
                minWidth={0}
                trim
            />
        </BaseAttribute>
    )
}

const AttachmentsAttributeEditor: FieldsWidgetAttributeComponent<AttachmentsAttributeProps> = ({
    field,
    isLoading: providedIsLoading,
    ...props
}) => {
    const { value, isLoading, onClearValue, onUpload, onRemoveAttachment } =
        useAttachmentsAttributeEditorState({
            isLoading: providedIsLoading,
        })

    const isEmpty = !isLoading && (!value || value.length < 1)

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
            className={AttachmentsAttributeStyle}
        >
            <Box flex column flexWrap="wrap" gap="xs" shrink alignSelf="stretch">
                {value?.map((attachment) => (
                    <Box key={attachment.url} minWidth={0} maxWidth="full">
                        <Skeleton
                            isLoading={isLoading}
                            key={attachment.url}
                            style={{
                                maxWidth: '100%',
                                minWidth: '50px',
                            }}
                        >
                            <Tag
                                size="xs"
                                variant="secondary"
                                startIcon={{ name: 'Paperclip' }}
                                type="outline"
                                shade="light"
                                color="Neutral"
                                noShrink
                                tabIndex={-1}
                                style={{
                                    wordBreak: 'break-word',
                                    maxWidth: '100%',
                                }}
                                showRemoveButton
                                onRemove={() => onRemoveAttachment(attachment.url)}
                                {...attachment.attributes}
                            >
                                {attachment.name}
                            </Tag>
                        </Skeleton>
                    </Box>
                ))}
                <Box>
                    <ReactFilestack
                        onSuccess={onUpload}
                        customRender={({ onPick }: { onPick: () => void }) => (
                            <Button
                                variant="ghost"
                                startIcon={{ name: 'Plus' }}
                                size="xs"
                                onClick={onPick}
                            >
                                Upload file
                            </Button>
                        )}
                    />
                </Box>
            </Box>
        </BaseAttribute>
    )
}
