import React from 'react'

import { ActivityFeed } from 'features/Activity/ActivityFeed'
import {
    Widget,
    WidgetAdminControlsComponent,
    WidgetComponent,
} from 'features/views/LayoutEditor/types'

import { Box } from 'ui/components/Box'

import { useFeedWidgetState } from './hooks/useFeedWidgetState'

export type FeedWidgetType = Widget<{}>

type FeedWidgetProps = {}

export const FeedWidget: WidgetComponent<FeedWidgetType, FeedWidgetProps> = () => {
    const { objectSid, recordSid, feedTarget, feedParams, showFeed } = useFeedWidgetState()

    if (!showFeed) return null

    return (
        <Box py="m">
            <ActivityFeed
                key={recordSid}
                contextKey={objectSid!}
                target={feedTarget}
                params={feedParams}
                showLocationPreview={true}
                feedWrapperProps={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    width: 'auto',
                    minHeight: 'auto',
                }}
            />
        </Box>
    )
}

type FeedWidgetControlsProps = {}

export const FeedWidgetAdminControls: WidgetAdminControlsComponent<
    FeedWidgetType,
    FeedWidgetControlsProps
> = () => {
    return <Box />
}
