import React from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'

import { stopPropagation } from 'ui/helpers/utilities'

import { useCheckboxAttributeState } from './hooks/useCheckboxAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type CheckboxAttributeProps = {}

export const CheckboxAttribute: FieldsWidgetAttributeComponent<CheckboxAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditable, onClick, onKeyDown } = useCheckboxAttributeState({
        field,
        isLoading,
    })

    return (
        <BaseAttribute
            {...props}
            onClick={stopPropagation}
            field={field}
            isLoading={isLoading}
            onKeyDown={onKeyDown}
        >
            <CheckboxAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                cursor={isEditable ? 'pointer' : undefined}
                onClick={onClick}
            />
        </BaseAttribute>
    )
}
