import React from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Popup, PopupContent, PopupInner, PopupTrigger } from 'ui/components/Popup'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { makeLineTruncationStyle, stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDropdownAttributeDisplayState } from './hooks/useDropdownAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

const sizeMapping = {
    tag: {
        s: '2xs',
        m: 'xs',
    },
} as const

type DropdownAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    maxOptions?: number
    maxLines?: number
    maxItemLength?: number
    size?: ResponsiveValue<'s' | 'm'>
    isLoading?: boolean
    showOnlyCount?: boolean
}

export const DropdownAttributeDisplay: AttributeDisplayComponent<
    string | string[],
    DropdownAttributeDisplayProps
> = React.memo(function DropdownAttributeDisplay({
    value,
    maxOptions,
    field,
    style,
    maxLines,
    maxItemLength,
    size = 'm',
    isLoading,
    showOnlyCount = false,
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)
    const tagSize = sizeMapping.tag[effectiveSize]

    const {
        options,
        overflowingOptions,
        overflowingOptionsCountLabel,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
        gridTemplateColumns,
    } = useDropdownAttributeDisplayState({
        field,
        value,
        maxOptions,
        maxItemLength,
        isLoading,
        showOnlyCount,
    })

    if (options.length === 0 && overflowingOptions.length === 0) {
        return null
    }

    return (
        <Box
            display="grid"
            center
            gap="xs"
            style={{
                ...makeLineTruncationStyle(maxLines),
                gridTemplateColumns,
                ...style,
            }}
            minWidth={0}
            maxWidth="full"
            {...props}
        >
            {options.map((option) => (
                <Skeleton
                    isLoading={isLoading}
                    key={option.value}
                    style={{
                        maxWidth: '100%',
                        minWidth: '50px',
                    }}
                >
                    <Tag
                        color={option.color}
                        size={tagSize}
                        noShrink
                        pointerEvents="none"
                        tabIndex={-1}
                        mb={maxLines !== 1 ? 'xs' : undefined}
                        mr={maxLines !== 1 ? 'xs' : undefined}
                        style={{
                            maxWidth: '100%',
                            minWidth: '50px',
                        }}
                    >
                        <Box trim maxWidth="full" py="3xs">
                            {option.label}
                        </Box>
                    </Tag>
                </Skeleton>
            ))}
            {overflowingOptionsCountLabel && (
                <Skeleton isLoading={isLoading}>
                    <Box noShrink onClick={stopPropagation}>
                        <Popup open={isOverflowPopupOpen} onOpenChange={onOverflowPopupOpenChange}>
                            <PopupTrigger
                                asChild
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onFocus={onOverflowLabelFocus}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                onBlur={onOverflowLabelBlur}
                            >
                                <Tag type="solid" color="Neutral" size={tagSize}>
                                    {overflowingOptionsCountLabel}
                                </Tag>
                            </PopupTrigger>
                            <PopupContent
                                sideOffset={0}
                                onOpenAutoFocus={stopPropagation}
                                onCloseAutoFocus={stopPropagation}
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                side="bottom"
                                align="end"
                                style={{
                                    width: 'auto',
                                    maxWidth: '300px',
                                }}
                            >
                                <PopupInner flex center gap="xs" wrap>
                                    {overflowingOptions.map((option) => (
                                        <Tag
                                            color={option.color}
                                            size={tagSize}
                                            key={option.value}
                                            noShrink
                                            pointerEvents="none"
                                            mb="xs"
                                            tabIndex={-1}
                                            minWidth={0}
                                            maxWidth="full"
                                        >
                                            {option.label}
                                        </Tag>
                                    ))}
                                </PopupInner>
                            </PopupContent>
                        </Popup>
                    </Box>
                </Skeleton>
            )}
        </Box>
    )
}, isEqual)
