import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { PickerResponse } from 'filestack-js'

import { useAttachmentsAttributeDisplayState } from 'features/views/attributes/hooks/useAttachmentsAttributeDisplayState'
import { AttachmentValue } from 'features/views/attributes/types'
import { FieldsWidgetFieldValueStyles } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import { useAttributeContext } from './useAttributeContext'

type UseAttachmentsAttributeEditorStateProps = {
    isLoading?: boolean
}

export function useAttachmentsAttributeEditorState({
    isLoading: providedIsLoading,
}: UseAttachmentsAttributeEditorStateProps) {
    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
        isEditingValue,
    } = useAttributeContext<AttachmentValue[]>()

    const [value, setValue] = useState(attributeValue ?? [])
    const valueRef = useRef(value)
    valueRef.current = value

    const onBlur = useCallback(() => {
        queueMicrotask(() => {
            replaceValue(valueRef.current as any)
            saveValue()
        })
    }, [saveValue, replaceValue])

    const onUpload = useCallback(
        (data: PickerResponse) => {
            const newAttachments = data.filesUploaded.map((file) => ({
                filename: file.filename,
                mimetype: file.mimetype,
                size: file.size,
                url: file.url,
            }))

            setValue((prevValue) => [...prevValue, ...newAttachments])

            onBlur()
        },
        [onBlur]
    )

    const onClearValue = useCallback(() => {
        clearValue()
    }, [clearValue])

    const onRemoveAttachment = useCallback((url: string) => {
        setValue((prevValue) => {
            return prevValue.filter((value) => {
                if (typeof value === 'string') {
                    return value !== url
                }

                return value.url !== url
            })
        })
    }, [])

    const { attachments, isLoading } = useAttachmentsAttributeDisplayState({
        value,
        isLoading: providedIsLoading,
    })

    useLayoutEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (!!document.querySelector('.ag-custom-component-popup')) {
                return
            }

            if (e.key === 'Enter') {
                e.preventDefault()
                onBlur()
            }

            if (e.key === 'Escape') {
                e.preventDefault()
                discardChanges()
            }
        }

        if (isEditingValue) {
            document.addEventListener('keydown', onKeyDown)
        }

        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [discardChanges, onBlur, isEditingValue])

    useLayoutEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (!!document.querySelector('.ag-custom-component-popup')) {
                return
            }

            const isEditingClassName = `.${FieldsWidgetFieldValueStyles.styleFunction({ isEditing: true }).split(' ').join('.')}`

            // If we click on the value container, just ignore.
            const targetEl = e?.target as HTMLElement
            if (!!targetEl?.closest(isEditingClassName)) {
                e!.preventDefault()
                e!.stopPropagation()
                return
            }

            onBlur()
        }

        if (isEditingValue) {
            document.addEventListener('click', onClick)
        }

        return () => {
            document.removeEventListener('click', onClick)
        }
    }, [onBlur, isEditingValue])

    return useMemo(
        () => ({
            onUpload,
            onBlur,
            value: attachments,
            onClearValue,
            isLoading,
            onRemoveAttachment,
        }),
        [onUpload, onBlur, attachments, onClearValue, isLoading, onRemoveAttachment]
    )
}
