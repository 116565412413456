import React, { useState } from 'react'

import { useAgentConversations } from 'data/hooks/agents/conversations'
import { useAgentInstructions } from 'data/hooks/agents/instructions'
import { Agent, AgentConversation } from 'data/hooks/agents/types'
import { AgentChatBox } from 'features/Agents/chat/AgentChatBox'
import { TRIGGER_TYPES } from 'features/Agents/configuration/triggers/constants'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body, Headline } from 'ui/components/Text'

type AgentConversationsProps = {
    agent: Agent
}

export function AgentConversations({ agent }: AgentConversationsProps) {
    const [selectedConversationSid, setSelectedConversationSid] = useState<string>()
    const { data: conversations } = useAgentConversations(agent._sid, { refetchOnMount: 'always' })

    return (
        <Box flex height="full" width="full" maxHeight="full" shrink>
            {/* Left sidebar with conversations list */}
            <Box
                width="300px"
                borderRightWidth="base"
                borderColor="borderWeak"
                flex
                column
                noShrink
                maxHeight="full"
            >
                <Box flex alignItems="center" justifyContent="space-between" px="l" py="l">
                    <Headline size="xs">History</Headline>
                    {/* <Button
                        variant="ghost"
                        startIcon={{ name: 'Plus' }}
                        onClick={() => setSelectedConversationSid(undefined)}
                    >
                        New Chat
                  </Button> */}
                </Box>

                <Box flex column gap="xs" overflowY="auto" shrink grow maxHeight="full">
                    {conversations?.map((conversation) => (
                        <ConversationListItem
                            key={conversation._sid}
                            conversation={conversation}
                            isSelected={selectedConversationSid === conversation._sid}
                            onSelect={() => setSelectedConversationSid(conversation._sid)}
                        />
                    ))}
                </Box>
            </Box>

            {/* Right pane with chat */}
            <Box flex grow p="m" shrink center column overflowY="auto">
                <Box maxWidth="700px" width="full" pt="4xl">
                    {selectedConversationSid && (
                        <AgentChatBox
                            agent={agent}
                            conversationSid={selectedConversationSid}
                            setConversationSid={setSelectedConversationSid}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

function ConversationListItem({
    conversation,
    isSelected,
    onSelect,
}: {
    conversation: AgentConversation
    isSelected: boolean
    onSelect: () => void
}) {
    const { data: instructionsList } = useAgentInstructions(conversation.agent_id)
    const instructions = instructionsList?.find(
        (i) => !!i.triggers.find((t) => t._sid === conversation.trigger_id)
    )
    const trigger = instructions?.triggers.find((t) => t._sid === conversation.trigger_id)
    const triggerType = trigger ? TRIGGER_TYPES[trigger.type] : undefined
    return (
        <Box
            key={conversation._sid}
            p="m"
            background={isSelected ? 'surfaceStrong' : 'surface'}
            borderRadius="m"
            cursor="pointer"
            onClick={() => onSelect()}
            flex
            alignItems="center"
            gap="m"
        >
            <Icon name={triggerType?.icon ?? 'MessageSquare'} color="textWeak" />
            <Box flex column grow>
                <Body trim>{instructions?.name || 'Untitled Conversation'}</Body>
                <Body size="xs" color="textWeak">
                    {new Date(conversation.created_date).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                    })}
                </Body>
            </Box>
        </Box>
    )
}
