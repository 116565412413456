import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'

import { Textarea } from 'ui/components/Textarea'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useLongTextAttributeEditorState } from './hooks/useLongTextAttributeEditorState'
import { BaseAttribute } from './BaseAttribute'
import { LongTextValue } from './LongTextValue'
import { FieldsWidgetAttributeComponent } from './types'

import { LongTextAttributeEditorStyle } from './LongTextAttribute.css'

const MAX_LINES = 3

type LongTextAttributeProps = {}

export const LongTextAttribute: FieldsWidgetAttributeComponent<LongTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue } = useAttributeContext<string>()
    const isEmpty = !isLoading && !value

    if (isEditingValue) {
        return (
            <LongTextAttributeEditor
                key="long-text-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="long-text-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            center={false}
        >
            <LongTextValue textValue={value} maxLines={MAX_LINES}>
                <LongTextAttributeDisplay
                    value={value}
                    field={field.field}
                    isLoading={isLoading}
                    size="m"
                    style={{
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap',
                    }}
                />
            </LongTextValue>
        </BaseAttribute>
    )
}

const LongTextAttributeEditor: FieldsWidgetAttributeComponent<LongTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue, editorRef } =
        useLongTextAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
        >
            <Textarea
                richText={false}
                ref={editorRef as any}
                size="m"
                autoSize
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
                className={LongTextAttributeEditorStyle}
            />
        </BaseAttribute>
    )
}
