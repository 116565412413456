import React from 'react'

import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'

import { Box } from 'ui/components/Box'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { theme } from 'ui/styling/Theme.css'

import { useDetailViewActionControlsState } from './hooks/useDetailViewActionControlsState'

type DetailViewActionControlsProps = {}

export const DetailViewActionControls: React.FC<DetailViewActionControlsProps> = () => {
    const { object, selectedButtons, updateConfig, editingMode, updateEditingMode } =
        useDetailViewActionControlsState()

    return (
        <Box flex column gap="l">
            {object && (
                <>
                    <LayoutEditorCollapsibleControl
                        label="Buttons"
                        startIcon={{ name: 'MousePointerClick' }}
                    >
                        <Box style={{ margin: '-8px 0 -16px 0' }}>
                            <ActionsContextProvider object={object}>
                                <ActionButtonsSelector
                                    object={object}
                                    selectedButtons={selectedButtons}
                                    setConfig={updateConfig}
                                    showHeader={true}
                                    canDeleteActions={true}
                                    canEditActions={true}
                                    canSearchActions={true}
                                    canReorderActions={true}
                                    animate={false}
                                    height="auto"
                                    maxHeight="none"
                                />
                            </ActionsContextProvider>
                        </Box>
                    </LayoutEditorCollapsibleControl>
                    <LayoutEditorControlSeparator />
                </>
            )}
            <LayoutEditorCollapsibleControl label="Editing" startIcon={{ name: 'FilePenLine' }}>
                <RadioCardGroup
                    value={editingMode}
                    onValueChange={updateEditingMode}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, auto-fill)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard value="disabled" icon={{ name: 'EyeOff' }}>
                        Disabled
                    </RadioCard>
                    <RadioCard value="inline" icon={{ name: 'MoreHorizontal' }}>
                        Inline
                    </RadioCard>
                </RadioCardGroup>
            </LayoutEditorCollapsibleControl>
        </Box>
    )
}
