import { useCallback, useMemo } from 'react'

import { FieldsWidgetAttributeField } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/types'

import { useAttributeContext } from './useAttributeContext'

type UseCheckboxAttributeStateProps = {
    field: FieldsWidgetAttributeField
    isLoading?: boolean
}

export function useCheckboxAttributeState({ field, isLoading }: UseCheckboxAttributeStateProps) {
    const { value = false, mutateValue, saveValue } = useAttributeContext<boolean>()

    const isEditable = field.isEditable && !isLoading

    const onClick = useCallback(
        (e?: React.MouseEvent<HTMLDivElement>) => {
            if (isEditable && !isLoading) {
                e?.preventDefault()
                e?.stopPropagation()

                mutateValue((data) => {
                    return !data as any
                })
                queueMicrotask(() => {
                    saveValue()
                })
            }
        },
        [isEditable, mutateValue, saveValue, isLoading]
    )

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                onClick()
            }
        },
        [onClick]
    )

    return useMemo(
        () => ({
            value,
            isEditable,
            onClick,
            onKeyDown,
        }),
        [value, isEditable, onClick, onKeyDown]
    )
}
