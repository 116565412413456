import React from 'react'

import { NumberAttributeDisplay } from 'features/views/attributes/NumberAttributeDisplay'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useNumberAttributeEditorState } from './hooks/useNumberAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type NumberAttributeProps = {}

export const NumberAttribute: FieldsWidgetAttributeComponent<NumberAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue } = useAttributeContext<number>()
    const isEmpty = !isLoading && typeof value === 'undefined'

    if (isEditingValue) {
        return (
            <NumberAttributeEditor
                key="number-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="number-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            <NumberAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                size="m"
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}

const NumberAttributeEditor: FieldsWidgetAttributeComponent<NumberAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue } =
        useNumberAttributeEditorState()

    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
            />
        </BaseAttribute>
    )
}
