import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { toYType } from 'features/utils/useYjsState'
import { useDropdownAttributeDisplayState } from 'features/views/attributes/hooks/useDropdownAttributeDisplayState'
import { FieldsWidgetFieldValueStyles } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useAttributeContext } from './useAttributeContext'

type UseDropdownAttributeEditorStateProps = {
    isLoading?: boolean
}

export function useDropdownAttributeEditorState({
    isLoading: providedIsLoading,
}: UseDropdownAttributeEditorStateProps) {
    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
        field,
        isEditingValue,
        isSavingSlow,
    } = useAttributeContext<string | string[]>()

    const isSingle = field.field.type === 'dropdown'
    const defaultValue = isSingle ? '' : []

    const [value, setValue] = useState(attributeValue ?? defaultValue)
    const valueRef = useRef(value)
    valueRef.current = value
    const valueString = Array.isArray(value) ? value.join(',') : value
    const valueSet = useMemo(() => new Set(isSingle ? [value] : value), [value, isSingle])

    const allOptions = useDeepEqualsMemoValue(
        field.field.options?.options?.map((option) => option.value!) ?? []
    )

    // We map all options to the value of the attribute.
    const { options } = useDropdownAttributeDisplayState({
        value: allOptions,
        field: field.field,
        isLoading: providedIsLoading,
    })

    const valueOptions = useMemo(() => {
        const valuesSet = new Set(valueString.split(','))
        return options.filter((option) => valuesSet.has(option.value))
    }, [options, valueString])

    const selectableOptions = useMemo(() => {
        if (isSingle) {
            return [...options].sort((a, b) => {
                // Show selected value first.
                if (valueString === a.value) {
                    return -1
                }

                if (valueString === b.value) {
                    return 1
                }

                return 0
            })
        }

        // For multi-select, we only want to show options that are not already selected.
        const valuesSet = new Set(valueString.split(','))
        return options.filter((option) => !valuesSet.has(option.value))
    }, [isSingle, options, valueString])

    const [isOpen, setIsOpen] = useState(true)
    useEffect(() => {
        if (isSavingSlow) {
            setIsOpen(false)
        } else if (isEditingValue) {
            setIsOpen(true)
        }
    }, [isSavingSlow, isEditingValue])

    const onClearValue = useCallback(() => {
        setValue('')
        clearValue()
        setIsOpen(false)
    }, [clearValue])

    const onInteractOutside = useCallback(
        (e?: CustomEvent<any>) => {
            const originalEvent = e?.detail.originalEvent as React.MouseEvent<HTMLElement>
            if (originalEvent?.defaultPrevented) {
                e!.preventDefault()
                e!.stopPropagation()
                return
            }

            const isEditingClassName = `.${FieldsWidgetFieldValueStyles.styleFunction().split(' ').join('.')}`

            // If we click on the value container, just ignore.
            const targetEl = originalEvent?.target as HTMLElement
            if (!!targetEl?.closest(isEditingClassName)) {
                e!.preventDefault()
                e!.stopPropagation()
                return
            }

            queueMicrotask(() => {
                const value = valueRef.current
                if (!!value.length) {
                    replaceValue(toYType(value))
                    saveValue()
                } else {
                    clearValue()
                }
            })
        },
        [saveValue, clearValue, replaceValue]
    )

    const onValueChange = useCallback(
        (value: string, checked: boolean) => {
            setValue((prev) => {
                let newValue: string | string[] = value

                if (checked) {
                    if (isSingle) {
                        newValue = value
                    } else {
                        newValue = [...prev, value]
                    }
                } else {
                    if (isSingle) {
                        newValue = ''
                    } else {
                        newValue = (prev as string[]).filter((v) => v !== value)
                    }
                }

                return newValue
            })

            if (isSingle) {
                onInteractOutside()
            }
        },
        [isSingle, onInteractOutside]
    )

    const onEscapeKeyDown = useCallback(() => {
        discardChanges()
    }, [discardChanges])

    return useMemo(
        () => ({
            value: valueSet,
            isSingle,
            onClearValue,
            valueOptions,
            selectableOptions,
            onValueChange,
            isOpen,
            onOpenChange: setIsOpen,
            onEscapeKeyDown,
            onInteractOutside,
        }),
        [
            valueSet,
            isSingle,
            onClearValue,
            valueOptions,
            selectableOptions,
            onValueChange,
            isOpen,
            onEscapeKeyDown,
            onInteractOutside,
        ]
    )
}
