import { getCustomBackendConfig } from 'utils/getCustomBackendConfig'

let BACKEND_ENDPOINT = import.meta.env.VITE_BACKEND_ENDPOINT || 'http://local.stackerlabs.com:8000'
const DOMAIN = import.meta.env.VITE_DOMAIN || 'local.stackerlabs.com:8443'
export const SERVER_PAGE_SIZE = 1000

const WORKSPACE_DOMAIN = DOMAIN
const STUDIO_URL = DOMAIN
const SUPPORTED_STUDIO_DOMAINS = [DOMAIN]

export const THUMBNAILS_URL = 'https://thumbnails.stackerhq.com/'

const IS_LOCAL_INSTANCE =
    DOMAIN.endsWith('.local') || DOMAIN.endsWith('8443') || DOMAIN.startsWith('local.')
const IS_PROD = DOMAIN === 'go.stackerhq.com'
const IS_TESTENV = DOMAIN === 'testenv.stackerhq.com'

let HOST = import.meta.env.VITE_HOST || window.location.host.replace(/^www\./, '')

const WEBSOCKET_SERVER = IS_PROD
    ? 'https://ws.go.stackerhq.com'
    : IS_LOCAL_INSTANCE
      ? 'https://localhost:3001'
      : 'https://ws.testenv.stackerhq.com'

/**
 * @see https://www.notion.so/stacker/Use-any-workspace-locally-ad6f88a16bf84917b3c2b3e3efd41b58
 * USE_CUSTOM_BACKEND is the proxy's url
 */
let USE_CUSTOM_BACKEND = import.meta.env.VITE_USE_CUSTOM_BACKEND

if (USE_CUSTOM_BACKEND) {
    const isActive = window.localStorage.getItem('customBackendSettings_isActive')
    if (isActive === '1') {
        const origin = getCustomBackendConfig()['x-origin'] //  used to compute which workspace you're on

        if (origin) {
            HOST = origin.replace(/https?:\/\//, '')
            BACKEND_ENDPOINT = USE_CUSTOM_BACKEND
        }
    }
}

const pusherSettings = {
    dev: {
        key: 'f399d0b5d0c230aa15a9',
        cluster: 'eu',
    },
    production: {
        key: 'd230bcf5682dc940080d',
        cluster: 'eu',
    },
    testenv: {
        key: 'cda609220411abd7f6fe',
        cluster: 'eu',
    },
}

const segment = {
    dev: {
        key: 'jBX8DaLdGebfwXz32MqDA5hp355HjmfX',
    },
    prod: {
        key: 'ecCCSNBBa7Xs1RPxb1pAmPIw2JweIMoG',
    },
}

const posthog = {
    // posthog is disabled locally by providing an empty key and host
    local: {
        key: '',
        host: '',
    },
    dev: {
        key: 'phc_LNOlTPQ7nk0FSadUjr9KdWzrKup8bWSBrCmSUIq9VlB',
        host: 'https://eu.i.posthog.com',
    },
    prod: {
        key: 'phc_vl51sWhCnm9ZN8SRw0ePJzncuPiz8KyeOjrS0lux9tB',
        host: 'https://eu.i.posthog.com',
    },
}

const launchDarkly = {
    dev: {
        clientSideId: '65804bfc4e378110af8bb99f',
        logLevel: 'error',
    },
    prod: {
        clientSideId: '65804bfc4e378110af8bb9a0',
        logLevel: 'error',
    },
}

const filestack = {
    dev: {
        key: 'AWNlF7GwvTci1r6RDTFmkz',
        location: 'gcs',
        cname: 'files.stackerhq.com',
    },
    prod: {
        key: 'Arfn2PRozQ26eRkdQy74Ez',
        location: 'gcs',
        cname: 'files.stackerhq.com',
    },
}

const nango = {
    dev: {
        key: import.meta.env.VITE_OVERRIDE_NANGO_DEV_KEY ?? 'cd21b36f-6659-4fe8-8047-280bc041c7b7',
    },
    prod: {
        key: '5a6debd7-b172-46b9-8d04-941e4edfabce',
    },
}

const agGrid = {
    dev: {
        key: 'Using_this_{AG_Grid}_Enterprise_key_{AG-070774}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Stacker_Software_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Stacker}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Stacker}_need_to_be_licensed___{Stacker}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_January_2026}____[v3]_[01]_MTc2NzY1NzYwMDAwMA==b28ac0f5412958df6d33438846bf3a82',
    },
    prod: {
        key: 'Using_this_{AG_Grid}_Enterprise_key_{AG-070774}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Stacker_Software_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Stacker}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Stacker}_need_to_be_licensed___{Stacker}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_January_2026}____[v3]_[01]_MTc2NzY1NzYwMDAwMA==b28ac0f5412958df6d33438846bf3a82',
    },
}

const userflow = {
    dev: {
        key: 'ct_kgactuiu2naktpz4gryye6xbqy',
    },
    prod: {
        key: 'ct_3bcjiy6xqfhhppkpobhalxyrxy',
    },
}

const settings = {
    DOMAIN,
    NODE_ENV: process.env.NODE_ENV,
    BACKEND_ENDPOINT,
    API_ROOT: `${BACKEND_ENDPOINT}/api/`,
    DEBUG: process.env.NODE_ENV === 'development',
    IS_PROD,
    WORKSPACE_DOMAIN,
    STUDIO_URL: 'https://' + STUDIO_URL,
    SUPPORTED_STUDIO_DOMAINS,
    STUDIO_DOMAIN: STUDIO_URL,
    IS_LOCAL_INSTANCE,
    OAUTH_REDIRECT_URL: IS_LOCAL_INSTANCE
        ? 'http://local.stackerlabs.com:8000/api/' // DNS redirect to 127.0.0.1
        : `${BACKEND_ENDPOINT}/api/`,
    PUSHER: IS_PROD
        ? pusherSettings.production
        : IS_TESTENV
          ? pusherSettings.testenv
          : pusherSettings.dev,
    USE_CUSTOM_BACKEND,
    HOST,
    SEGMENT_KEY: segment.prod.key,
    POSTHOG: IS_PROD ? posthog.prod : IS_LOCAL_INSTANCE ? posthog.local : posthog.dev,
    LAUNCH_DARKLY: IS_PROD ? launchDarkly.prod : launchDarkly.dev,
    FILESTACK: IS_PROD ? filestack.prod : filestack.dev,
    ASSETS_URL: (window as any).runtimeSettings?.REACT_ASSETS_URL,
    NANGO_KEY: IS_PROD ? nango.prod.key : nango.dev.key,
    AG_GRID_KEY: IS_PROD ? agGrid.prod.key : agGrid.dev.key,
    USERFLOW_KEY: IS_PROD ? userflow.prod.key : userflow.dev.key,
    WEBSOCKET_SERVER,
}

export default settings
export const GOOGLE_OAUTH_CLIENT_ID = IS_TESTENV
    ? '1057951522302-tcobfscd5ot2g00sgu9gvta7cb5cljrg.apps.googleusercontent.com'
    : '507339879785-dceijhahlut3r0s4q5phghmt09g3cl60.apps.googleusercontent.com'

export const ALGOLIA_APP_ID = IS_TESTENV ? 'JBBUEEUMBV' : '9DWUU1A67U'
export const ALGOLIA_API_KEY = IS_TESTENV
    ? 'b1b10c658edba5dd141ce72bc436567b'
    : '35070f202351c463f312686841fb517a'
export const ALGOLIA_INDEX_ID = IS_TESTENV ? 'testenv' : IS_LOCAL_INSTANCE ? '' : 'astra_prod'
export const TIP_TAP_COLLAB_APP_ID = IS_TESTENV ? 'xy9dqpke' : 'je973rm0'
export const DD_RUM_APP_ID = '6c5b4ffc-b05f-47a7-9405-579eb9c2ce53'
export const DD_RUM_TOKEN = 'pub4f8219c928a5fb3a753710f5339e5af7'

export const LIGHTGALLERY_LICENSE_KEY = '85ED9437-274F47D0-B8C276A2-21E2F125'
