export const DATA_CONNECTION_DETAIL_MODAL_KEY = 'DataConnectionDetailModal'

// integration ids of integration which don't have an equivalent concept of database, so
// database selection UI should not be shown
export const INTEGRATION_IDS_WITHOUT_DATABASES: ExternalIntegrationId[] = ['hubspot'] as const
// integration ids of integration which support webhooks for realtime data updates
export const INTEGRATION_IDS_WITH_WEBHOOK_SUPPORT: ExternalIntegrationId[] = ['airtable'] as const

export const TRANSLATIONS: Record<ExternalIntegrationId, Record<string, string>> = {
    airtable: {
        Database: 'Base',
        databases: 'bases',
        database: 'base',
        tables: 'tables',
        table: 'table',
    },
    hubspot: {
        Database: 'Account',
        databases: 'accounts',
        database: 'account',
        tables: 'tables',
        table: 'table',
    },
} as const

export const INTEGRATION_ID_TO_NAME: Record<ExternalIntegrationId, string> = {
    airtable: 'Airtable',
    hubspot: 'HubSpot',
} as const

export const INTEGRATION_ID_TO_DC_TYPE: Record<ExternalIntegrationId, DataConnectionType> = {
    airtable: 'nango_airtable',
    hubspot: 'nango_hubspot',
} as const

export const DC_TYPE_TO_INTEGRATION_ID = Object.fromEntries(
    Object.entries(INTEGRATION_ID_TO_DC_TYPE).map(([key, value]) => [value, key])
) as Record<DataConnectionType, ExternalIntegrationId>

// mapping of integration id to description
export const INTEGRATIONS_DESCRIPTION: Record<ExternalIntegrationId, string> = {
    airtable: 'Effortlessly bring your Airtable info into one place.',
    hubspot: 'Transfer HubSpot CRM data in real-time.',
} as const
