import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

export const SaveAndDiscardFormButtons: React.FC<{ error?: string }> = ({ error }) => {
    const {
        formState: { isDirty },
        reset,
    } = useFormContext()

    return (
        <Box flex column mt="3xl">
            <Box flex gap="s">
                <Button type="submit" size="m" variant="primary" disabled={!isDirty}>
                    Save Changes
                </Button>

                {isDirty && (
                    <Button
                        type="reset"
                        size="m"
                        variant="ghost"
                        onClick={(e) => {
                            e.preventDefault()
                            reset()
                        }}
                    >
                        Discard Changes
                    </Button>
                )}
            </Box>
            {error && (
                <Body color="textError" mt="m">
                    {error}
                </Body>
            )}
        </Box>
    )
}
