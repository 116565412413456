import React from 'react'

import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import FieldListEditor from 'v2/ui/components/FieldsEditor/FieldListEditor'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
} from 'ui/components/Dropdown'
import { RadioButton, RadioCard, RadioCardGroup, RadioGroup } from 'ui/components/Radio'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

import { useInlineFiltersControlsState } from './hooks/useInlineFiltersControlsState'
import { useRecommendedFieldsSelectorState } from './hooks/useRecommendedFieldsSelectorState'

type InlineFiltersControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
}

export const InlineFiltersControls: React.FC<InlineFiltersControlsProps> = ({
    setConfig,
    config,
}) => {
    const { object, allFields } = useListViewContext()

    return (
        <ListViewControlItem label="Inline filters" icon="Settings2">
            <InlineFiltersGenericControls
                config={config}
                setConfig={setConfig}
                fields={allFields}
                object={object}
                width="300px"
            />
        </ListViewControlItem>
    )
}

type InlineFiltersGenericControlsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    config: ListViewOptions
    setConfig: (config: Partial<ListViewOptions>) => void
    fields: FieldDto[]
    object: ObjectDto
}

export const InlineFiltersGenericControls: React.FC<InlineFiltersGenericControlsProps> = ({
    config,
    setConfig,
    fields,
    object,
    ...props
}) => {
    const {
        filterFieldType,
        handleFieldTypeChange,
        handleInlineFiltersTypeChange,
        handleUpdateSelectedFieldSids,
        inlineFiltersType,
        selectedFieldSids,
        supportedFields,
        supportedRecommendedFields,
        onRecommendedFieldsChange,
        recommendedFieldsSids,
    } = useInlineFiltersControlsState({ config, setConfig, fields })

    return (
        <Box flex flexDirection="column" gap="l" {...props}>
            <Box>
                <Body size="s" display="block">
                    Activate inline filters to enable end users of your application to apply
                    filters. You can also add filters for fields hidden from the list view.
                </Body>
            </Box>
            <Box role="group">
                <RadioCardGroup
                    value={inlineFiltersType}
                    onValueChange={handleInlineFiltersTypeChange}
                >
                    <RadioCard value="" icon={{ name: 'EyeOff' }}>
                        Disabled
                    </RadioCard>
                    <RadioCard value="quick" icon={{ name: 'PanelTopOpen' }}>
                        Quick
                    </RadioCard>
                    <RadioCard value="advanced" icon={{ name: 'Settings2' }}>
                        Advanced
                    </RadioCard>
                </RadioCardGroup>
            </Box>
            {!!inlineFiltersType && (
                <Box role="group" flexDirection="column" flex gap="m">
                    <Body size="m" weight="medium">
                        Allowed fields
                    </Body>
                    <RadioGroup
                        value={filterFieldType}
                        onValueChange={handleFieldTypeChange}
                        style={{
                            gap: theme.space.xs,
                        }}
                    >
                        <RadioButton value="all">
                            <Body size="s">Filter by all fields</Body>
                        </RadioButton>
                        <RadioButton value="specific">
                            <Body size="s">Choose fields to filter by</Body>
                        </RadioButton>
                    </RadioGroup>
                </Box>
            )}
            {!!inlineFiltersType && filterFieldType === 'specific' && (
                <Box width="full">
                    <FieldListEditor
                        object={object}
                        fields={supportedFields}
                        selectedItems={selectedFieldSids}
                        disableReorder={false}
                        allowEditSections={false}
                        disallowSections={true}
                        onUpdate={handleUpdateSelectedFieldSids}
                        maxHeight="230px"
                    />
                </Box>
            )}
            {inlineFiltersType === 'advanced' && (
                <Box role="group" flexDirection="column" flex gap="m">
                    <Body size="m" weight="medium">
                        Recommended fields
                    </Body>
                    <RecommendedFieldsSelector
                        fields={supportedRecommendedFields}
                        value={recommendedFieldsSids}
                        onChange={onRecommendedFieldsChange}
                    />
                </Box>
            )}
        </Box>
    )
}

const FIELD_LABEL_MAX_LENGTH = 25

type RecommendedFieldsSelectorProps = {
    fields: FieldDto[]
    value: string[]
    onChange: (value: string[]) => void
}

const RecommendedFieldsSelector: React.FC<RecommendedFieldsSelectorProps> = ({
    fields,
    value,
    onChange,
}) => {
    const { fieldOptions, onCheckedChange, valueSet, selectedOptions } =
        useRecommendedFieldsSelectorState({
            fields,
            value,
            onChange,
            maxLength: FIELD_LABEL_MAX_LENGTH,
        })

    return (
        <Box flex center wrap gap="xs">
            {selectedOptions.map((option) => (
                <Tag
                    key={option.value}
                    size="xs"
                    type="solid"
                    shape="rectangle"
                    color="Neutral"
                    shade="light"
                    onRemove={() => onCheckedChange(option.value, false)}
                    showRemoveButton
                    maxLength={FIELD_LABEL_MAX_LENGTH}
                >
                    {option.label}
                </Tag>
            ))}
            <Dropdown>
                <DropdownButton
                    startIcon={{ name: 'Plus' }}
                    variant="dotted"
                    size="xs"
                    aria-label="Select recommended fields"
                />
                <DropdownContent
                    side="bottom"
                    align="end"
                    onClick={stopPropagation}
                    head={<DropdownHeadSearch />}
                >
                    {fieldOptions.map((option) => (
                        <DropdownItem
                            key={option.value}
                            label={option.label}
                            checked={valueSet.has(option.value)}
                            onCheckedChange={(checked) => onCheckedChange(option.value, checked)}
                            startIcon={option.icon}
                            closeOnSelect={true}
                            multiSelect
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        </Box>
    )

    return null
}
