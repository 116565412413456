import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import {
    ADDITIONAL_ACTIONS_WIDTH,
    TAB_CHAR_WIDTH,
    TAB_CONTENT_GAP,
    TAB_MAX_WIDTH_ICON,
    TAB_MAX_WIDTH_NO_ICON,
    TAB_MIN_WIDTH_ICON,
    TAB_MIN_WIDTH_NO_ICON,
} from 'features/views/LayoutEditor/widgets/TabsWidget.css'
import { TabsWidgetType } from 'features/views/LayoutEditor/widgets/tabsWidgetTypes'

import { useTabsWidgetTabs } from './useTabsWidgetTabs'

export function useTabsWidgetState(widget: TabsWidgetType) {
    const { urlParams } = useLayoutEditorContext()
    const urlParamsRef = useRef(urlParams)
    urlParamsRef.current = urlParams

    const { visibleTabs } = useTabsWidgetTabs(widget)

    const widgetUrlSlug = widget.attrs.urlSlug || widget.id

    const setSelectedTabUrlSlug = useCallback(
        (tabUrlSlug?: string) => {
            urlParamsRef.current.setParam(widgetUrlSlug, tabUrlSlug, true)
        },
        [widgetUrlSlug]
    )

    const selectedTabUrlSlug = urlParams.values[widgetUrlSlug]

    const isSelectedTabVisible = !!visibleTabs.find((tab) => {
        const urlSlug = tab.urlSlug || tab.id

        return urlSlug === selectedTabUrlSlug
    })
    const firstTabUrlSlug = visibleTabs[0]?.urlSlug || visibleTabs[0]?.id
    useLayoutEffect(() => {
        const update = requestAnimationFrame(() => {
            // If the selected tab does not exist anymore, select the first tab.
            if (!isSelectedTabVisible) {
                setSelectedTabUrlSlug(firstTabUrlSlug)
            }
        })

        return () => cancelAnimationFrame(update)
    }, [setSelectedTabUrlSlug, isSelectedTabVisible, firstTabUrlSlug])

    const wrapperRef = useRef<HTMLDivElement>(null)

    const [overflowMaxTabsLength, setOverflowMaxTabsLength] = useState(0)
    const shouldOverflow = visibleTabs.length > overflowMaxTabsLength

    useLayoutEffect(() => {
        const wrapper = wrapperRef.current
        if (!wrapper) return

        const calculateOverflowMaxTabsLength = () => {
            const wrapperWidth = wrapper.clientWidth

            const maxAllowedTabsWidth = wrapperWidth - ADDITIONAL_ACTIONS_WIDTH

            let totalTabsWidth = 0
            let overflowMaxTabsLength = 0
            for (const tab of visibleTabs) {
                const tabMinWidth = tab.icon ? TAB_MIN_WIDTH_ICON : TAB_MIN_WIDTH_NO_ICON
                const tabMaxWidth = tab.icon ? TAB_MAX_WIDTH_ICON : TAB_MAX_WIDTH_NO_ICON

                let tabDesiredWidth = tab.title.length * TAB_CHAR_WIDTH + tabMinWidth
                if (!!tab.title) {
                    // The gap between the icon and the text is 6px.
                    tabDesiredWidth += TAB_CONTENT_GAP
                }
                let tabWidth = Math.min(Math.max(tabDesiredWidth, tabMinWidth), tabMaxWidth)

                let tempTotalTabsWidth = totalTabsWidth + tabWidth
                if (tempTotalTabsWidth > maxAllowedTabsWidth) {
                    // The tabs would overflow the parent in this case.
                    // Let's try to reduce the width of this tab.
                    const diffToCorrectWidth = tempTotalTabsWidth - maxAllowedTabsWidth
                    tabWidth -= diffToCorrectWidth
                    if (tabWidth < tabMinWidth) break

                    tempTotalTabsWidth -= diffToCorrectWidth
                }

                totalTabsWidth = tempTotalTabsWidth
                overflowMaxTabsLength++
            }

            setOverflowMaxTabsLength(overflowMaxTabsLength)
        }

        calculateOverflowMaxTabsLength()

        const resizeObserver = new ResizeObserver(() => {
            calculateOverflowMaxTabsLength()
        })
        resizeObserver.observe(wrapperRef.current)

        return () => {
            resizeObserver.disconnect()
        }
    }, [visibleTabs])

    return useMemo(
        () => ({
            selectedTabUrlSlug,
            setSelectedTabUrlSlug,
            visibleTabs,
            wrapperRef,
            shouldOverflow,
            overflowMaxTabsLength,
        }),
        [
            selectedTabUrlSlug,
            setSelectedTabUrlSlug,
            visibleTabs,
            shouldOverflow,
            overflowMaxTabsLength,
        ]
    )
}
