import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useAgentSkills } from 'data/hooks/agents/skills'
import { AgentInstructions } from 'data/hooks/agents/types'
import { EditEnabledSkills } from 'features/Agents/configuration/skills/EditEnabledSkills'
import { EditTools } from 'features/Agents/configuration/tools/EditTools'
import { EditTriggers } from 'features/Agents/configuration/triggers/EditTriggers'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { Headline } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'
import { Toggle } from 'ui/components/Toggle'

export function EditAgentInstructionsForm({
    agentSid,
    instructionsSid,
}: {
    agentSid: string
    instructionsSid?: string
}) {
    const {
        setValue,
        register,
        watch,
        formState: { errors },
    } = useFormContext<Partial<AgentInstructions>>()
    const { data: skills } = useAgentSkills(agentSid)
    return (
        <Box flex column gap="4xl" maxWidth="full">
            <Box flex column gap="l">
                <Headline size="xs">General</Headline>
                <Field
                    label="Name"
                    width="5xl"
                    shrink
                    isError={!!errors.name}
                    helperText={errors.name?.message}
                >
                    <Input
                        {...register('name', {
                            required: 'Please supply a name for this set of instructions',
                        })}
                    />
                </Field>
                <EditTriggers agentSid={agentSid} instructionsSid={instructionsSid} />
            </Box>
            <Box flex column gap="s">
                <Headline size="xs">Operating instructions</Headline>
                <Textarea
                    label="Describe the operating instructions for the agent"
                    placeholder="Enter operating instructions"
                    fieldProps={{ isError: !!errors.prompt, helperText: errors.prompt?.message }}
                    {...register('prompt', {
                        required: 'Please enter some operating instructions.',
                    })}
                    resizable
                    style={{ height: '200px' }}
                />
            </Box>

            {skills && <EditEnabledSkills agentSid={agentSid} skills={skills} />}
            <EditTools agentSid={agentSid} />

            <Field label="Run operations asynchronously" width="5xl" shrink>
                <Toggle
                    onCheckedChange={(checked) => setValue('options.async_jobs_enabled', checked)}
                    checked={watch('options.async_jobs_enabled')}
                />
            </Field>
        </Box>
    )
}
