import { useCallback, useMemo, useRef, useState } from 'react'

import { FieldsWidgetFieldValueControlsStyle } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import { useAttributeContext } from './useAttributeContext'

export function useTextAttributeEditorState() {
    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
    } = useAttributeContext<string>()

    const [value, setValue] = useState(attributeValue ?? '')
    const valueRef = useRef(value)
    valueRef.current = value

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setValue(value)
    }, [])

    const onInputBlur = useCallback(
        (e?: React.FocusEvent<HTMLInputElement>) => {
            // If the blur event is triggered by the field value controls, we don't want to save the value.
            const targetEl = e?.relatedTarget as HTMLElement | null
            if (targetEl?.closest(`.${FieldsWidgetFieldValueControlsStyle}`)) {
                return
            }

            queueMicrotask(() => {
                replaceValue(valueRef.current as any)
                saveValue()
            })
        },
        [saveValue, replaceValue]
    )

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                onInputBlur()
            }

            if (e.key === 'Escape') {
                e.preventDefault()
                discardChanges()
            }
        },
        [discardChanges, onInputBlur]
    )

    const onClearValue = useCallback(() => {
        setValue('')
        clearValue()
    }, [clearValue])

    return useMemo(
        () => ({
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            value,
            onClearValue,
        }),
        [onInputChange, onInputBlur, onInputKeyDown, value, onClearValue]
    )
}
