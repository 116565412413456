import React, { useCallback } from 'react'

import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'

import { Skeleton } from 'ui/components/Skeleton'
import { Textarea } from 'ui/components/Textarea'

import { useRichTextAttributeEditorState } from './hooks/useRichTextAttributeEditorState'
import { useRichTextAttributeState } from './hooks/useRichTextAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { LongTextValue } from './LongTextValue'
import { FieldsWidgetAttributeComponent } from './types'

import { RichTextAttributeEditorStyle, RichTextAttributeStyle } from './RichTextAttribute.css'

const MAX_LINES = 3

type RichTextAttributeProps = {}

export const RichTextAttribute: FieldsWidgetAttributeComponent<RichTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { isEmpty, content, recordSid, stackSid, value, isEditingValue } =
        useRichTextAttributeState({
            isLoading,
        })

    if (isEditingValue) {
        return (
            <RichTextAttributeEditor
                key="long-text-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            center={false}
        >
            <Skeleton isLoading={isLoading}>
                <LongTextValue textValue={value?.plainTextContent} maxLines={MAX_LINES}>
                    <TipTapDocumentEditor
                        content={content}
                        readOnly={true}
                        stackId={stackSid}
                        placeholder="Press '/' for commands or just start typing..."
                        width="full"
                        border={false}
                        recordId={recordSid}
                        fieldId={field?.sid}
                        allowComments={false}
                        className={RichTextAttributeStyle}
                        disableToolbar
                    />
                </LongTextValue>
            </Skeleton>
        </BaseAttribute>
    )
}

const RichTextAttributeEditor: FieldsWidgetAttributeComponent<RichTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue, editorRef, isEmpty } =
        useRichTextAttributeEditorState({ isLoading })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
        >
            <Textarea
                richText={true}
                ref={editorRef as any}
                size="m"
                autoSize
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                onClick={handleClick}
                value={value}
                width="full"
                autoFocus
                className={RichTextAttributeEditorStyle}
                fieldProps={{
                    style: {
                        minWidth: 0,
                    },
                }}
            />
        </BaseAttribute>
    )
}
