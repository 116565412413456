import React from 'react'

import { ListViewControlFieldInput } from 'features/views/ListView/ListViewControlFieldInput'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'

type BoardViewCardFooterControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
    objectSid: string
}

export const BoardViewCardFooterControls: React.FC<BoardViewCardFooterControlsProps> = ({
    fields,
    config,
    setConfig,
    objectSid,
}) => {
    return (
        <ListViewControlItem label="Card footer" icon="PanelBottom">
            <BoardViewCardFooterGenericControls
                maxWidth="full"
                minWidth="300px"
                objectSid={objectSid}
                fields={fields}
                config={config}
                setConfig={setConfig}
            />
        </ListViewControlItem>
    )
}

type BoardViewCardFooterGenericControlsProps = React.ComponentPropsWithoutRef<typeof Box> &
    BoardViewCardFooterControlsProps

export const BoardViewCardFooterGenericControls: React.FC<
    BoardViewCardFooterGenericControlsProps
> = ({ objectSid, fields, config, setConfig }) => {
    return (
        <Box flex flexDirection="column" gap="xl">
            <ListViewControlFieldInput
                objectSid={objectSid}
                label="Left area"
                fields={fields}
                value={config.boardCardFooter?.leftFieldSid}
                displayOptions={config.boardCardFooter?.leftFieldDisplayOptions}
                onChange={(value) => {
                    setConfig({
                        boardCardFooter: {
                            ...config.boardCardFooter,
                            leftFieldSid: value,
                        },
                    })
                }}
                onDisplayOptionsChange={(value) => {
                    setConfig({
                        boardCardFooter: {
                            ...config.boardCardFooter,
                            leftFieldDisplayOptions: value,
                        },
                    })
                }}
                showActions={false}
            />
            <ListViewControlFieldInput
                objectSid={objectSid}
                label="Right area"
                fields={fields}
                value={config.boardCardFooter?.rightFieldSid}
                displayOptions={config.boardCardFooter?.rightFieldDisplayOptions}
                onChange={(value) => {
                    setConfig({
                        boardCardFooter: {
                            ...config.boardCardFooter,
                            rightFieldSid: value,
                        },
                    })
                }}
                onDisplayOptionsChange={(value) => {
                    setConfig({
                        boardCardFooter: {
                            ...config.boardCardFooter,
                            rightFieldDisplayOptions: value,
                        },
                    })
                }}
                showActions={false}
            />
        </Box>
    )
}
