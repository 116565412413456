import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { useObject } from 'data/hooks/objects'
import { useActionsFromObjectId } from 'features/actions/helpers'
import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'
import { toYType } from 'features/utils/useYjsState'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetAdminControlsComponent } from 'features/views/LayoutEditor/types'
import { ListViewWidgetType } from 'features/views/LayoutEditor/widgets/ListViewWidget/listViewWidgetTypes'
import { convertWidgetToView } from 'features/views/LayoutEditor/widgets/ListViewWidget/utils'
import {
    isSystemAction,
    useSystemActionsGeneric,
} from 'features/views/ListView/Actions/hooks/useSystemActions'
import { BoardViewCardFooterGenericControls } from 'features/views/ListView/BoardView/BoardViewCardFooterControls'
import { BoardViewCardHeaderGenericControls } from 'features/views/ListView/BoardView/BoardViewCardHeaderControls'
import { CardViewCardFooterGenericControls } from 'features/views/ListView/CardView/CardViewCardFooterControls'
import { CardViewCardHeaderGenericControls } from 'features/views/ListView/CardView/CardViewCardHeaderControls'
import {
    getColumnConfigsFromWidget,
    getSupportedThumbnailFields,
    makeAllFields,
} from 'features/views/ListView/utils'

import FieldListEditor from 'v2/ui/components/FieldsEditor/FieldListEditor'
import { Item } from 'v2/ui/components/OrderableListSelector/types'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { RadioButton, RadioCard, RadioCardGroup, RadioGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

export const ListViewWidgetContentControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
    ...props
}) => {
    switch (widget.attrs.display) {
        case 'table':
            return <ListViewTableControls widget={widget} onChange={onChange} {...props} />
        case 'cards':
            return <ListViewCardsControls widget={widget} onChange={onChange} {...props} />
        case 'board':
            return <ListViewBoardControls widget={widget} onChange={onChange} {...props} />
    }
}

const ListViewFieldsControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const { objectSid, showAllFields } = widget.attrs
    const { object } = useObject(objectSid)
    const fields = makeAllFields(object)

    const prevObjectSid = useRef(objectSid)
    useEffectOnlyOnUpdate(() => {
        if (objectSid === prevObjectSid.current) return

        onChange((attrs) => {
            attrs.set('columnConfigs', [])
            attrs.set('showAllFields', true)
        })

        prevObjectSid.current = objectSid
    }, [objectSid, onChange])

    const onShowAllFieldsChange = useCallback(
        (value: 'showAllFields' | 'specific') => {
            onChange((attrs) => {
                attrs.set('showAllFields', value === 'showAllFields')
            })
        },
        [onChange]
    )

    const addFieldTargetRef = useRef<HTMLButtonElement>(null)

    const [isAddFieldOpen, setIsAddFieldOpen] = useState(false)

    const onAddFieldClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            setIsAddFieldOpen((prev) => !prev)
            e.preventDefault()
            e.stopPropagation()
        },
        [setIsAddFieldOpen]
    )

    const onAddFieldClose = useCallback(() => {
        setIsAddFieldOpen(false)
    }, [setIsAddFieldOpen])

    const handleColumnConfigChange = useCallback(
        (columnConfigs: ListViewColumnConfig[]) => {
            onChange((attrs) => {
                attrs.set('columnConfigs', toYType(columnConfigs))
            })
        },
        [onChange]
    )

    const effectiveColumnConfigs = getColumnConfigsFromWidget(widget, fields)

    if (!object) return null

    return (
        <LayoutEditorCollapsibleControl
            label="Fields"
            startIcon={{ name: 'MenuSquare' }}
            defaultOpen={false}
        >
            <Box flex column gap="l">
                <Box role="group" flex gap="m" mb="l" justifyContent="space-between">
                    <RadioGroup
                        value={showAllFields ? 'showAllFields' : 'specific'}
                        onValueChange={onShowAllFieldsChange}
                        style={{
                            gap: theme.space.xs,
                        }}
                    >
                        <RadioButton value="showAllFields">
                            <Body size="s">Show all fields</Body>
                        </RadioButton>
                        <RadioButton value="specific">
                            <Body size="s">Choose fields to display</Body>
                        </RadioButton>
                    </RadioGroup>
                    <Box noShrink>
                        <Button
                            variant="primary"
                            size="s"
                            startIcon={{ name: 'Plus' }}
                            ref={addFieldTargetRef}
                            onClick={onAddFieldClick}
                        >
                            Add new
                        </Button>
                    </Box>
                </Box>
                <FieldListEditor
                    object={object}
                    fields={fields}
                    selectedItems={effectiveColumnConfigs}
                    allowEditFields={false}
                    onUpdate={handleColumnConfigChange}
                    allFieldsModeEnabled={showAllFields}
                    disallowSections={true}
                    autoHideEditButton={false}
                    allowEditSections={false}
                    hideTopSection={true}
                    showConditionalVisibility={false}
                />
            </Box>
            <FieldEditorPopover
                objectId={object?._sid}
                open={isAddFieldOpen}
                target={addFieldTargetRef.current ?? undefined}
                onSuccess={onAddFieldClose}
                onCancel={onAddFieldClose}
                onClose={onAddFieldClose}
                placement="bottom-start"
            />
        </LayoutEditorCollapsibleControl>
    )
}

const ListViewCustomActionsControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const {
        actionDisplay: providedActionDisplay,
        display,
        actionButtons,
        objectSid,
        onRecordClick = 'preview',
    } = widget.attrs

    const { object } = useObject(objectSid)
    const systemActions = useSystemActionsGeneric(object)
    const showSystemActions = !!systemActions.length

    let actionDisplay = providedActionDisplay
    if (display !== 'table' && actionDisplay === 'buttons') {
        actionDisplay = 'dropdown'
    }

    const { data: userActions } = useActionsFromObjectId(object?._sid ?? '')
    const showCustomActions = !!actionDisplay || display === 'cards'
    const hasUserActions = showCustomActions && !!userActions?.length

    const selectedButtons = useMemo(() => {
        if (!!actionButtons) {
            return actionButtons
        }

        return systemActions.map((action) => ({
            id: action._sid,
            conditions: [],
        }))
    }, [actionButtons, systemActions])
    const selectedButtonsRef = useRef(selectedButtons)
    selectedButtonsRef.current = selectedButtons

    const updateActionButtonsConfig = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            const existingButtons = selectedButtonsRef.current
            const existingSystemButtons = existingButtons.filter((action) =>
                isSystemAction(action.id)
            )

            const newButtons = [...existingSystemButtons, ...pageButtons]
            onChange((attrs) => {
                attrs.set('actionButtons', toYType(newButtons))
            })
        },
        [onChange]
    )

    const updateSystemActionsConfig = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            const existingButtons = selectedButtonsRef.current
            const existingUserButtons = existingButtons.filter(
                (action) => !isSystemAction(action.id)
            )

            const newButtons = [...existingUserButtons, ...pageButtons]
            onChange((attrs) => {
                attrs.set('actionButtons', toYType(newButtons))
            })
        },
        [onChange]
    )

    const provideIcon = useCallback(
        (item: Item) => {
            const systemAction = systemActions.find((action) => action._sid === item.id)
            if (!systemAction?.icon) {
                return null
            }

            return <Icon size="m" name={systemAction.icon} mr="s" />
        },
        [systemActions]
    )

    return (
        <LayoutEditorCollapsibleControl
            label="Actions"
            startIcon={{ name: 'Zap' }}
            defaultOpen={false}
        >
            <RadioCardGroup
                value={actionDisplay ?? ''}
                onValueChange={(value: string) => {
                    onChange((attrs) => {
                        const newValue = value || undefined

                        attrs.set('actionDisplay', newValue)
                    })
                }}
            >
                <RadioCard value="" icon={{ name: 'EyeOff' }}>
                    None
                </RadioCard>
                {widget.attrs.display === 'table' && (
                    <RadioCard value="buttons" icon={{ name: 'Zap' }}>
                        Buttons
                    </RadioCard>
                )}
                <RadioCard value="dropdown" icon={{ name: 'Blinds' }}>
                    Dropdown
                </RadioCard>
            </RadioCardGroup>
            {(showCustomActions || showSystemActions) && (
                <Box role="group" mt="3xl">
                    {showCustomActions && (
                        <Box mt="l">
                            <Box
                                flex
                                center
                                style={{
                                    textTransform: 'uppercase',
                                    height: theme.space['3xl'],
                                }}
                            >
                                <Body size="xs" weight="bold" color="textWeaker">
                                    Custom actions
                                </Body>
                            </Box>
                            {hasUserActions ? (
                                <ActionButtonsSelector
                                    object={object}
                                    selectedButtons={selectedButtons}
                                    setConfig={updateActionButtonsConfig}
                                    showHeader={false}
                                    canDeleteActions={false}
                                    canEditActions={false}
                                    canSearchActions={false}
                                    animate={false}
                                    height="auto"
                                />
                            ) : (
                                <Box>
                                    <Body size="s" color="textWeakest">
                                        No custom actions configured
                                    </Body>
                                </Box>
                            )}
                        </Box>
                    )}
                    {showSystemActions && (
                        <Box mt="l">
                            <Box
                                flex
                                center
                                style={{
                                    textTransform: 'uppercase',
                                    height: theme.space['3xl'],
                                }}
                            >
                                <Body size="xs" weight="bold" color="textWeaker">
                                    System buttons
                                </Body>
                            </Box>
                            <ActionButtonsSelector
                                object={object}
                                additionalActions={systemActions}
                                selectedButtons={selectedButtons}
                                setConfig={updateSystemActionsConfig}
                                showHeader={false}
                                canDeleteActions={false}
                                canEditActions={false}
                                canSearchActions={false}
                                canReorderActions={false}
                                animate={false}
                                height="auto"
                                maxHeight="none"
                                showObjectActions={false}
                                provideIcon={provideIcon}
                            />
                        </Box>
                    )}
                </Box>
            )}
            <Select
                label="Click record action"
                defaultValue="preview"
                value={onRecordClick}
                onChange={(value: string) => {
                    onChange((attrs) => {
                        attrs.set('onRecordClick', value)
                    })
                }}
            >
                <SelectOption key="preview" value="preview" label="Open as preview" />
                <SelectOption key="detail" value="detail" label="Open as full page" />
                <SelectOption key="new_tab" value="new_tab" label="Open in new tab" />
                <SelectOption key="none" value="none" label="None" />
            </Select>
        </LayoutEditorCollapsibleControl>
    )
}

const ListViewTableControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
    ...props
}) => {
    const { objectSid, coverImageFieldApiName } = widget.attrs
    const { object } = useObject(objectSid)
    const fields = makeAllFields(object)

    const handleThumbnailFieldApiNameChange = useCallback(
        (fieldApiName: string) => {
            onChange((attrs) => {
                attrs.set('coverImageFieldApiName', fieldApiName)
            })
        },
        [onChange]
    )

    const thumbnailSupportedFields = getSupportedThumbnailFields(fields)

    if (!object) return null

    return (
        <Box flex column gap="l">
            <ListViewFieldsControls widget={widget} onChange={onChange} {...props} />
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Thumbnail"
                startIcon={{ name: 'Image' }}
                defaultOpen={false}
            >
                <Select
                    placeholder="Select field..."
                    label="Image"
                    value={coverImageFieldApiName}
                    onChange={handleThumbnailFieldApiNameChange}
                    isClearable
                    isSearchable
                >
                    {thumbnailSupportedFields.map((field) => (
                        <SelectOption key={field._sid} value={field.api_name} label={field.label} />
                    ))}
                </Select>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <ListViewCustomActionsControls widget={widget} onChange={onChange} {...props} />
        </Box>
    )
}

const ListViewCardsControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
    ...props
}) => {
    const { objectSid } = widget.attrs
    const { object } = useObject(objectSid)
    const fields = makeAllFields(object)

    const fakeView = convertWidgetToView(widget)

    const handleConfigChange = useCallback(
        (config: Partial<ListViewOptions>) => {
            onChange((attrs) => {
                for (const [key, value] of Object.entries(config)) {
                    if (key === 'coverImage') {
                        const newValue: ListViewOptions['coverImage'] = value

                        attrs.set('coverImageAspectRatio', newValue?.aspectRatio)
                        attrs.set('coverImageFieldApiName', newValue?.id)
                    } else if (key === 'profileImage') {
                        const newValue: ListViewOptions['profileImage'] = value

                        attrs.set('profileImageFieldApiName', newValue?.fieldApiName)
                    } else if (key.startsWith('card')) {
                        attrs.set(key, toYType(value))
                    }
                }
            })
        },
        [onChange]
    )

    if (!object) return null

    return (
        <Box flex column gap="l">
            <LayoutEditorCollapsibleControl
                label="Card header"
                startIcon={{ name: 'PanelTop' }}
                defaultOpen={false}
            >
                <CardViewCardHeaderGenericControls
                    objectSid={objectSid!}
                    config={fakeView.options}
                    setConfig={handleConfigChange}
                    fields={fields}
                    gap="l"
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <ListViewFieldsControls widget={widget} onChange={onChange} {...props} />
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Card footer"
                startIcon={{ name: 'PanelBottom' }}
                defaultOpen={false}
            >
                <CardViewCardFooterGenericControls
                    objectSid={objectSid!}
                    config={fakeView.options}
                    setConfig={handleConfigChange}
                    fields={fields}
                    gap="l"
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <ListViewCustomActionsControls widget={widget} onChange={onChange} {...props} />
        </Box>
    )
}

const ListViewBoardControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
    ...props
}) => {
    const { objectSid, statusFieldSid, statusColumns } = widget.attrs
    const { object } = useObject(objectSid)
    const fields = makeAllFields(object)

    const fakeView = convertWidgetToView(widget)

    const handleConfigChange = useCallback(
        (config: Partial<ListViewOptions>) => {
            onChange((attrs) => {
                for (const [key, value] of Object.entries(config)) {
                    if (key === 'coverImage') {
                        const newValue: ListViewOptions['coverImage'] = value
                        attrs.set('coverImageAspectRatio', newValue?.aspectRatio)
                        attrs.set('coverImageFieldApiName', newValue?.id)
                    } else if (key === 'profileImage') {
                        const newValue: ListViewOptions['profileImage'] = value
                        attrs.set('profileImageFieldApiName', newValue?.fieldApiName)
                    } else if (key.startsWith('board')) {
                        attrs.set(key, toYType(value))
                    }
                }
            })
        },
        [onChange]
    )

    const statusFieldOptions = fields.filter((field) => field.type === 'dropdown')
    const statusFieldOptionsRef = useRef(statusFieldOptions)
    statusFieldOptionsRef.current = statusFieldOptions

    useEffect(() => {
        // If there is no status field selected, set the first status field as the default
        if (!statusFieldSid) {
            const defaultStatusField = statusFieldOptionsRef.current[0]
            if (!defaultStatusField) return

            onChange((attrs) => {
                attrs.set('statusFieldSid', defaultStatusField._sid)
            })
        }
    }, [onChange, statusFieldSid, objectSid])

    const statusField = statusFieldOptions.find((field) => field._sid === statusFieldSid)

    const columnOptions = statusField?.options?.options ?? []

    const statusColumnsValue = statusColumns?.map((column) => column || 'none') ?? []

    if (!object) return null

    return (
        <Box flex column gap="l">
            <LayoutEditorCollapsibleControl
                label="Columns"
                startIcon={{ name: 'Columns3' }}
                defaultOpen={false}
            >
                <Box flex column gap="l">
                    <Select
                        label="Grouped by"
                        placeholder="Pick a field"
                        value={statusFieldSid}
                        onChange={(value: string) => {
                            onChange((attrs) => {
                                attrs.set('statusFieldSid', value)
                            })
                        }}
                    >
                        {statusFieldOptions.map((field) => (
                            <SelectOption key={field._sid} value={field._sid} label={field.label} />
                        ))}
                    </Select>
                    {!!statusField && (
                        <Select
                            label="Visible columns"
                            placeholder="Pick columns"
                            multiSelect
                            value={statusColumnsValue}
                            onChange={(value: string[]) => {
                                onChange((attrs) => {
                                    const newValue = value.map((value) => {
                                        if (value === 'none') return null

                                        return value
                                    })

                                    attrs.set('statusColumns', toYType(newValue))
                                })
                            }}
                        >
                            {columnOptions.map((column) => (
                                <SelectOption
                                    key={column.value}
                                    value={column.value!}
                                    label={column.label}
                                />
                            ))}
                            <SelectOption value="none" label="None" />
                        </Select>
                    )}
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Card header"
                startIcon={{ name: 'PanelTop' }}
                defaultOpen={false}
            >
                <BoardViewCardHeaderGenericControls
                    objectSid={objectSid!}
                    config={fakeView.options}
                    setConfig={handleConfigChange}
                    fields={fields}
                    gap="l"
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <ListViewFieldsControls widget={widget} onChange={onChange} {...props} />
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Card footer"
                startIcon={{ name: 'PanelBottom' }}
                defaultOpen={false}
            >
                <BoardViewCardFooterGenericControls
                    objectSid={objectSid!}
                    config={fakeView.options}
                    setConfig={handleConfigChange}
                    fields={fields}
                    gap="l"
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <ListViewCustomActionsControls widget={widget} onChange={onChange} {...props} />
        </Box>
    )
}
