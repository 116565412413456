import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { TabsWidget, TabsWidgetAdminControls } from './TabsWidget'
import { TabsWidgetType } from './tabsWidgetTypes'
import { getTabWidgetUrlSlug } from './tabsWidgetUtils'

export const TabsWidgetMeta: WidgetSchema<TabsWidgetType> = {
    category: 'layout',
    label: 'Tabs',
    icon: 'Folders',
    component: TabsWidget,
    adminControlsComponent: TabsWidgetAdminControls,
    isContainer: true,
    defaultAttrs: (_id, view) => {
        return {
            tabs: [],
            urlSlug: getTabWidgetUrlSlug(view),
        }
    },
    requireFields: (attrs, fields) => {
        const fieldsSet = new Set<string>()

        for (const tab of attrs.tabs) {
            for (const condition of tab.conditions) {
                if (!!condition.field_sid) {
                    const field = fields?.get(condition.field_sid)
                    if (!!field) {
                        fieldsSet.add(field.api_name)
                    }
                }
            }
        }

        return Array.from(fieldsSet)
    },
}
