import React, { useLayoutEffect } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { DetailView } from './DetailView'

type EmbeddedDetailViewProps = {
    viewSid: string
    recordSid?: string
}

export const EmbeddedDetailView: React.FC<EmbeddedDetailViewProps> = ({ viewSid, recordSid }) => {
    const { commands } = useLayoutEditorContext()

    useLayoutEffect(() => {
        commands.initEditor({ viewSid })

        return () => {
            commands.closeEditor()
        }
    }, [commands, viewSid])

    return <DetailView isEmbedded={true} recordSid={recordSid} />
}
