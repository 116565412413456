import React from 'react'

import { DetailViewContent } from './DetailViewContent'
import { DetailViewContextProvider } from './DetailViewContext'

type DetailViewProps = {
    isEmbedded?: boolean
    recordSid?: string
}

export const DetailView: React.FC<DetailViewProps> = ({ isEmbedded = false, recordSid }) => {
    return (
        <DetailViewContextProvider isEmbedded={isEmbedded} recordSid={recordSid}>
            <DetailViewContent />
        </DetailViewContextProvider>
    )
}
