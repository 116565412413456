import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useTextAttributeEditorState } from './hooks/useTextAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type TextAttributeProps = {}

export const TextAttribute: FieldsWidgetAttributeComponent<TextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue } = useAttributeContext<string>()
    const isEmpty = !isLoading && !value

    if (isEditingValue) {
        return (
            <TextAttributeEditor
                key="text-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="text-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            <TextAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                size="m"
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                maxWidth="full"
                minWidth={0}
                trim
            />
        </BaseAttribute>
    )
}

const TextAttributeEditor: FieldsWidgetAttributeComponent<TextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue } =
        useTextAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
            />
        </BaseAttribute>
    )
}
