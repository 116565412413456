import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAgents } from 'data/hooks/agents/agents'
import { Frame } from 'features/core/Frame'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Headline } from 'ui/components/Text'

import { AgentChatBox } from './chat/AgentChatBox'
import { AgentIcon } from './chat/AgentIcon'
import { AgentJobs } from './chat/AgentJobs'
import { AgentSettings } from './configuration/AgentSettings'
import { AgentConversations } from './conversations/AgentConversations'
import { AgentPageHeader } from './AgentPageHeader'

type AgentPageTab = 'chat' | 'conversations' | 'tasks' | 'settings'

export const AgentPage: React.FC = () => {
    const { agentId } = useParams<{ agentId: string }>()
    const { data: agents } = useAgents()
    const [conversationSid, setConversationSid] = useState<string>()
    const [currentTab, setCurrentTab] = useState<AgentPageTab>('chat')

    if (!agentId) {
        return <div>No agent ID provided</div>
    }

    const agent = agents?.find((a) => a._sid === agentId)

    if (!agent) {
        return <div>Agent not found</div>
    }

    const tabSection = (
        <Box>
            <Button
                startIcon={{ name: 'MessageCircle' }}
                variant={currentTab === 'chat' ? 'activated' : 'ghost'}
                size="s"
                onClick={() => setCurrentTab('chat')}
                aria-selected={currentTab === 'chat'}
            >
                Chat
            </Button>
            <Button
                startIcon={{ name: 'Clock' }}
                variant={currentTab === 'conversations' ? 'activated' : 'ghost'}
                size="s"
                onClick={() => setCurrentTab('conversations')}
                aria-selected={currentTab === 'conversations'}
            >
                History
            </Button>
            <Button
                startIcon={{ name: 'ClipboardCheck' }}
                variant={currentTab === 'tasks' ? 'activated' : 'ghost'}
                size="s"
                onClick={() => setCurrentTab('tasks')}
                aria-selected={currentTab === 'tasks'}
            >
                Tasks
            </Button>
            <Button
                startIcon={{ name: 'Cog' }}
                variant={currentTab === 'settings' ? 'activated' : 'ghost'}
                size="s"
                onClick={() => setCurrentTab('settings')}
                aria-selected={currentTab === 'settings'}
            >
                Settings
            </Button>
        </Box>
    )

    const titleSection = (
        <>
            <AgentIcon agent={agent} size="xl" />
            <Headline size={{ mobile: 'xs' }}>{agent.title}</Headline>
        </>
    )

    return (
        <Frame background="white" paddingOverride="0" hidePoweredBy>
            <Box flex column grow height="full" style={{ maxHeight: '100vh' }}>
                <AgentPageHeader
                    title={agent.title}
                    tabSection={tabSection}
                    titleSection={titleSection}
                />
                <Box
                    flex
                    column
                    height="full"
                    shrink
                    width="full"
                    p={0}
                    overflowY="auto"
                    alignItems="center"
                >
                    {currentTab === 'chat' && (
                        <Box
                            flex
                            column
                            height="full"
                            width="700px"
                            maxWidth="700px"
                            pt="l"
                            alignItems="center"
                            pb="4xl"
                            grow
                        >
                            <AgentChatBox
                                agent={agent}
                                conversationSid={conversationSid}
                                setConversationSid={setConversationSid}
                            />
                        </Box>
                    )}{' '}
                    {currentTab === 'conversations' && <AgentConversations agent={agent} />}
                    {currentTab === 'tasks' && (
                        <Box width="700px" maxWidth="700px" pt="l" grow>
                            <AgentJobs agent={agent} />
                        </Box>
                    )}
                    {currentTab === 'settings' && <AgentSettings agent={agent} />}
                </Box>
            </Box>
        </Frame>
    )
}
