import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { FieldsWidgetType } from 'features/views/LayoutEditor/widgets/FieldsWidget/fieldWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useFieldsWidgetFields } from './useFieldsWidgetFields'

// minWidth: columnCount
const CONTAINER_COLUMN_COUNTS: Record<string, number> = {
    '0': 1,
    '421': 2,
    '801': 3,
    '1201': 4,
}
const CONTAINER_WIDTHS = [1201, 801, 421, 0]

type UseFieldsWidgetStateProps = {
    widget: FieldsWidgetType
}

export function useFieldsWidgetState({ widget }: UseFieldsWidgetStateProps) {
    const { record, isFetchingSlow } = useRecordManagerContext()

    const { visibleFields } = useFieldsWidgetFields(widget)

    const { isEditing } = useLayoutEditorContext()

    const showFieldIcon = widget.attrs.showFieldIcon ?? false
    const labelPlacement = widget.attrs.labelPlacement || 'top'

    const [columnCount, setColumnCount] = useState(1)
    const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null)
    useLayoutEffect(() => {
        const wrapper = wrapperRef
        if (!wrapper) return

        const calculateColumnCount = () => {
            const width = wrapper.clientWidth

            const columnCount = CONTAINER_WIDTHS.find((key) => {
                return width >= key
            })
            if (!columnCount) return

            setColumnCount(CONTAINER_COLUMN_COUNTS[columnCount.toString()])
        }

        calculateColumnCount()

        const resizeObserver = new ResizeObserver(calculateColumnCount)
        resizeObserver.observe(wrapper)

        return () => {
            resizeObserver.disconnect()
        }
    }, [wrapperRef])

    const fieldsByColumn: (typeof visibleFields)[] = useMemo(() => {
        const columns: (typeof visibleFields)[] = Array.from({ length: columnCount }, () => [])

        for (let i = 0; i < visibleFields.length; i++) {
            const field = visibleFields[i]

            const targetColumnIdx = i % columnCount
            columns[targetColumnIdx].push(field)
        }

        return columns
    }, [visibleFields, columnCount])
    const fieldsByColumnMemo = useDeepEqualsMemoValue(fieldsByColumn)

    const hasFields = visibleFields.length > 0

    const [editingFieldsApiNames, setEditingFieldsApiNames] = useState<string[]>([])

    const isEditingRef = useRef(isEditing)
    isEditingRef.current = isEditing

    const toggleEditingField = useCallback((fieldApiName: string, enabled: boolean) => {
        if (isEditingRef.current) return

        setEditingFieldsApiNames((prev) => {
            if (enabled) {
                return [...prev, fieldApiName]
            }

            return prev.filter((name) => name !== fieldApiName)
        })
    }, [])

    const hasRecord = !!record

    return useMemo(
        () => ({
            record,
            fieldsByColumn: fieldsByColumnMemo,
            isFetchingSlow,
            showFieldIcon,
            labelPlacement,
            setWrapperRef,
            hasFields,
            hasRecord,
            toggleEditingField,
            editingFieldsApiNames,
        }),
        [
            record,
            fieldsByColumnMemo,
            isFetchingSlow,
            showFieldIcon,
            labelPlacement,
            hasFields,
            hasRecord,
            toggleEditingField,
            editingFieldsApiNames,
        ]
    )
}
