import React from 'react'

import { DateAttributeDisplay } from 'features/views/attributes/DateAttributeDisplay'

import { DatePicker } from 'ui/components/DatePicker'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useDateAttributeEditorState } from './hooks/useDateAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

import { DateAttributeEditorStyle } from './DateAttribute.css'

type DateAttributeProps = {}

export const DateAttribute: FieldsWidgetAttributeComponent<DateAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue } = useAttributeContext<string>()
    const isEmpty = !isLoading && !value

    if (isEditingValue) {
        return (
            <DateAttributeEditor
                key="date-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="date-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            <DateAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                size="m"
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}

const DateAttributeEditor: FieldsWidgetAttributeComponent<DateAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, showTime, dateFormat, onChange, onClearValue, onBlur } =
        useDateAttributeEditorState(field)

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            showClearValueButton={!isEmpty}
            onClearValue={onClearValue}
        >
            <DatePicker
                inline={false}
                value={value}
                showTime={showTime}
                onChange={onChange}
                dateFormat={dateFormat}
                customInput={<AttributeTextInput />}
                popperPlacement="bottom-start"
                onCalendarClose={onBlur}
                autoFocus
                preventOpenOnFocus={false}
                wrapperClassName={DateAttributeEditorStyle}
            />
        </BaseAttribute>
    )
}
