import React, { useContext } from 'react'

import { BreadcrumbsItem } from 'features/breadcrumbs/types'
import { DetailViewHeader } from 'features/views/DetailView/types'
import { RecordManager } from 'features/views/RecordManager/hooks/useRecordManagerState'

export type DetailViewContextValue = {
    view?: ViewDto
    viewPath?: string
    object?: ObjectDto
    recordManager: RecordManager
    header: DetailViewHeader
    deleteRecord: () => void
    duplicateRecord: () => void
    isPreviewing: boolean
    breadcrumbs: BreadcrumbsItem[]
    prevBreadcrumb?: BreadcrumbsItem
    requestIncludedFields: string[]
    contentWidth: 'full' | 'boxed'
    widgetStyle: 'compact' | 'spacious'
    isEmbedded: boolean
}
export const DetailViewContext = React.createContext<DetailViewContextValue | null>(null)

export function useDetailViewContext(): DetailViewContextValue | null {
    return useContext(DetailViewContext)
}
