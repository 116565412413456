import { useCallback } from 'react'

import { useObject } from 'data/hooks/objects'
import { toYType } from 'features/utils/useYjsState'
import { getYActions } from 'features/views/DetailView/utils'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { getYLayout } from 'features/views/LayoutEditor/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useDetailViewActionControlsState() {
    const { view, commands } = useLayoutEditorContext()

    const { object } = useObject(view?.object_id)

    const selectedButtons: ActionButton[] = useDeepEqualsMemoValue(
        view?.layout?.actions?.pageButtons || []
    )

    const updateConfig = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            commands.transaction((data) => {
                const actions = getYActions(data)
                actions?.set('pageButtons', toYType(pageButtons))
            })
        },
        [commands]
    )

    const editingMode = view?.layout?.editingMode ?? 'inline'

    const updateEditingMode = useCallback(
        (editingMode: string) => {
            commands.transaction((data) => {
                const layout = getYLayout(data)
                layout.set('editingMode', editingMode)
            })
        },
        [commands]
    )

    return {
        object,
        selectedButtons,
        updateConfig,
        editingMode,
        updateEditingMode,
    }
}
