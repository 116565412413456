export const DEFAULT_WIDGET_AREA_ID = 'content'

export const WIDGET_CATEGORIES = {
    layout: {
        id: 'layout',
        label: 'Layout',
    },
    data: {
        id: 'data',
        label: 'Data',
    },
    hidden: {
        id: 'hidden',
        label: 'Hidden',
    },
} as const

export const CATEGORIES_ORDER: Record<keyof typeof WIDGET_CATEGORIES, number> = {
    layout: 0,
    data: 1,
    hidden: 2,
}
