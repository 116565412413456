import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { toYType } from 'features/utils/useYjsState'
import { getAvatarFromUserRecord } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { UserItem } from 'features/views/attributes/hooks/useUsersState'
import { FieldsWidgetFieldValueStyles } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'
import { useLookupRecords } from 'features/views/ListView/hooks/useLookupRecords'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useAttributeContext } from './useAttributeContext'

const DEBOUNCE_RATE = 200

type Option = {
    label: string
    value: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

export function useRecordLinksAttributeEditorState() {
    const { record } = useRecordManagerContext()
    const recordRef = useRef(record)
    recordRef.current = record

    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
        field,
        isSavingSlow,
        isEditingValue,
    } = useAttributeContext<string | string[]>()

    const isSingle = field.field.type === 'lookup'
    const defaultValue = isSingle ? '' : []

    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(setDebouncedSearchQuery, DEBOUNCE_RATE)

    const handleChangeSearchQuery = useCallback(
        (query: string) => {
            setSearchQuery(query)
            applyDebouncedSearchQuery(query)
        },
        [applyDebouncedSearchQuery]
    )

    const [value, setValue] = useState(attributeValue ?? defaultValue)
    const valueRef = useRef(value)
    valueRef.current = value
    const valueString = Array.isArray(value) ? value.join(',') : value
    const valueSet = useMemo(() => new Set(isSingle ? [value] : value), [value, isSingle])

    const valueRecordSids: string[] = useDeepEqualsMemoValue(
        isSingle ? [value as string] : (value as string[])
    )

    const { isError, records, isFetchingSlow, isFetching } = useLookupRecords({
        field: field.field,
        searchQuery: debouncedSearchQuery,
        alwaysShowValueRecords: true,
        valueRecordSids: valueRecordSids,
    })
    const recordsRef = useRef(records)
    recordsRef.current = records

    const hasValue = valueSet.size > 0
    const isLabelLoading = hasValue && (!records || isFetching)

    const usersObject = useStackerUsersObject()
    const options = isFetchingSlow ? PLACEHOLDER_OPTIONS : makeOptions(records, usersObject)

    const objectSid = field.field.link_target_object_id
    const isUsersObject = objectSid === usersObject?._sid

    const valueOptions = useMemo(() => {
        if (isFetchingSlow) {
            return PLACEHOLDER_OPTIONS
        }

        const valuesSet = new Set(valueString.split(','))
        return options.filter((option) => valuesSet.has(option.value))
    }, [options, valueString, isFetchingSlow])

    const usersValueOptions: UserItem[] = useMemo(() => {
        if (!isUsersObject) return []

        return valueOptions.map((r) => ({ ...r.avatar! }))
    }, [isUsersObject, valueOptions])

    const selectableOptions = useMemo(() => {
        if (isSingle) {
            return [...options].sort((a, b) => {
                // Show selected value first.
                if (valueString === a.value) {
                    return -1
                }

                if (valueString === b.value) {
                    return 1
                }

                return 0
            })
        }

        // For multi-select, we only want to show options that are not already selected.
        const valuesSet = new Set(valueString.split(','))
        return options.filter((option) => !valuesSet.has(option.value))
    }, [isSingle, options, valueString])

    const [isOpen, setIsOpen] = useState(true)
    useEffect(() => {
        if (isSavingSlow) {
            setIsOpen(false)
        } else if (isEditingValue) {
            setIsOpen(true)
        }
    }, [isSavingSlow, isEditingValue])

    const onClearValue = useCallback(() => {
        setValue('')
        clearValue()
        setIsOpen(false)
    }, [clearValue])

    const onInteractOutside = useCallback(
        (e?: CustomEvent<any>) => {
            const originalEvent = e?.detail.originalEvent as React.MouseEvent<HTMLElement>
            if (originalEvent?.defaultPrevented) {
                e!.preventDefault()
                e!.stopPropagation()
                return
            }

            const isEditingClassName = `.${FieldsWidgetFieldValueStyles.styleFunction({ isEditing: true }).split(' ').join('.')}`

            // If we click on the value container, just ignore.
            const targetEl = e?.target as HTMLElement
            if (!!targetEl?.closest(isEditingClassName)) {
                e!.preventDefault()
                e!.stopPropagation()
                return
            }

            queueMicrotask(async () => {
                const value = valueRef.current
                if (!!value.length) {
                    replaceValue(toYType(value))
                    saveValue()
                } else {
                    clearValue()
                }
            })
        },
        [saveValue, replaceValue, clearValue]
    )

    const onValueChange = useCallback(
        (value: string, checked: boolean) => {
            setValue((prev) => {
                let newValue: string | string[] = value

                if (checked) {
                    if (isSingle) {
                        newValue = value
                    } else {
                        newValue = [...prev, value]
                    }
                } else {
                    if (isSingle) {
                        newValue = ''
                    } else {
                        newValue = (prev as string[]).filter((v) => v !== value)
                    }
                }

                return newValue
            })

            if (isSingle) {
                onInteractOutside()
            }
        },
        [isSingle, onInteractOutside]
    )

    const onEscapeKeyDown = useCallback(() => {
        discardChanges()
    }, [discardChanges])

    return useMemo(
        () => ({
            isError,
            value: valueSet,
            isSingle,
            onClearValue,
            valueOptions,
            selectableOptions,
            onValueChange,
            isOpen,
            onOpenChange: setIsOpen,
            onEscapeKeyDown,
            onInteractOutside,
            searchQuery,
            setSearchQuery: handleChangeSearchQuery,
            usersValueOptions,
            isLabelLoading,
            isFetchingSlow,
            isUsersObject,
        }),
        [
            isError,
            valueSet,
            isSingle,
            onClearValue,
            valueOptions,
            usersValueOptions,
            selectableOptions,
            onValueChange,
            isOpen,
            onEscapeKeyDown,
            onInteractOutside,
            searchQuery,
            handleChangeSearchQuery,
            isLabelLoading,
            isFetchingSlow,
            isUsersObject,
        ]
    )
}

function makeOptions(records?: RecordDto[], usersObject?: ObjectDto): Option[] {
    if (!records) {
        return []
    }

    const options = records.map((record) => {
        const avatar = getAvatarFromUserRecord(record, usersObject)

        const recordPrimary = record?._primary || ''
        const label = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()

        return {
            label,
            value: record._sid,
            avatar,
        }
    })

    options.sort((a, b) => {
        // Sort by label alphabetically.
        if (a.label.localeCompare(b.label) < 0) return -1
        if (a.label.localeCompare(b.label) > 0) return 1

        return 0
    })

    return options
}

const PLACEHOLDER_OPTIONS: Option[] = [
    { label: 'Loading options...', value: 'loading1' },
    { label: 'Loading options...', value: 'loading2' },
    { label: 'Loading options...', value: 'loading3' },
    { label: 'Loading options...', value: 'loading4' },
    { label: 'Loading options...', value: 'loading5' },
    { label: 'Loading options...', value: 'loading6' },
]
