import { useCallback, useRef, useState } from 'react'
import { QueryKey, useMutation, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    buildQueryKey,
    queryClient,
    useCanRunStackScopedQueries,
    useQuery,
    useQueryKeyBuilder,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { useWebsocketListener } from 'data/websockets/useWebsocketListener'
import {
    ChatMessageBase,
    ChatMessageRemove,
    ChatUserMessage,
} from 'features/AiAppBuilder/chatUtils/chatTypes'
import { ChatMessageContentPart } from 'features/AiAppBuilder/chatUtils/openAiTypes'

import { ToolCallResult } from './types'

const CHAT_LIST_NAME = 'useAgentConversationChat'
const getEndpoint = (agent_sid: string, conversation_sid: string) =>
    `agents/${agent_sid}/conversations/${conversation_sid}/chat/`

function useChatQueryKey(conversationSid: string) {
    return useQueryKeyBuilder([CHAT_LIST_NAME, conversationSid], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

export type ConversationResponseData = {
    messages?: (ChatMessageBase | ChatMessageRemove)[]
    interrupt_messages?: ChatMessageBase[]
    tool_call_results?: ToolCallResult[]
    conversation_sid: string
    pendingMessage?: ChatUserMessage
}

type MessagesOptionsType = UseQueryOptions<
    ConversationResponseData,
    unknown,
    ConversationResponseData,
    QueryKey
>

export function useAgentConversationChatState(
    agent_sid: string,
    session_id: string,
    conversation_sid?: string,
    options: MessagesOptionsType = {}
) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as MessagesOptionsType),
        keepPreviousData: false,
        ...options,
        enabled: enabled && !!conversation_sid,
    }
    return useQuery<ConversationResponseData>(
        useChatQueryKey(conversation_sid || session_id),
        getEndpoint(agent_sid, conversation_sid || ''),
        query_config
    )
}

type PostMessageArguments = {
    agentSid: string
    conversationSid?: string
    message: string | Array<ChatMessageContentPart>
    sessionId: string
}

async function postMessageApi(args: PostMessageArguments, processAsync: boolean = false) {
    const { agentSid, conversationSid, message, sessionId } = args
    const endpoint = getEndpoint(agentSid, conversationSid || 'new')

    const response = await fetchWithAuth(endpoint, {
        method: 'POST',
        body: JSON.stringify({
            message,
            session_id: sessionId,
            process_async: processAsync,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })

    if (response.status >= 400) {
        return Promise.reject(response)
    }

    return response.json() as unknown as ConversationResponseData
}

export function usePostChatMessageAsync(sessionId: string) {
    const [isProcessing, setIsProcessing] = useState(false)
    const tempId = useRef<string>(sessionId)
    const rejectPromise = useRef<((reason?: any) => void) | undefined>()
    const resolvePromise = useRef<((value: ConversationResponseData) => void) | undefined>()

    const updateQueryCache = useCallback((data: ConversationResponseData) => {
        const { conversation_sid } = data

        // Update cache with actual messages
        queryClient.setQueryData<ConversationResponseData>(
            buildQueryKey([CHAT_LIST_NAME, conversation_sid], {
                includeAuthKeys: true,
                includeStackId: true,
            }),
            (old) => {
                let finalMessages = (old?.messages ?? []).filter(
                    (m) => !data.messages?.find((m2) => m2.id === m.id) && m.id !== tempId.current
                )

                for (const message of data.messages ?? []) {
                    if (message.type === 'remove') {
                        finalMessages = finalMessages.filter((m) => m.id !== message.id)
                    } else {
                        finalMessages.push(message)
                    }
                }

                return {
                    ...old!,
                    messages: finalMessages,
                    tool_call_results: [
                        ...(old?.tool_call_results ?? []),
                        ...(data.tool_call_results ?? []),
                    ],
                    interrupt_messages: data.interrupt_messages,
                    pendingMessage: undefined,
                }
            }
        )
    }, [])

    const setPendingMessageAsFailed = useCallback(
        (conversationSid: string) => {
            // Mark message as failed
            queryClient.setQueryData<ConversationResponseData>(
                buildQueryKey([CHAT_LIST_NAME, conversationSid || sessionId], {
                    includeAuthKeys: true,
                    includeStackId: true,
                }),
                (old) => ({
                    ...old!,
                    pendingMessage:
                        old?.pendingMessage && old?.pendingMessage?.id === tempId.current
                            ? { ...old!.pendingMessage, isSending: false, sendingFailed: true }
                            : old?.pendingMessage,
                })
            )
        },
        [sessionId]
    )

    useWebsocketListener(sessionId, (message: unknown) => {
        if (message && typeof message === 'object' && 'conversation_updates' in message) {
            const data = message.conversation_updates as ConversationResponseData
            updateQueryCache(data)
            resolvePromise.current?.(data)
            setIsProcessing(false)
        } else if (message && typeof message === 'object' && 'chat_message_failed' in message) {
            rejectPromise.current?.(new Error(message.chat_message_failed as string))
        }
    })

    const postMessageAsync = useCallback(
        async (args: PostMessageArguments) => {
            setIsProcessing(true)

            tempId.current = 'temp-' + Date.now()

            // Create optimistic message
            const optimisticMessage: ChatUserMessage = {
                id: tempId.current,
                type: 'human',
                content: args.message,
                isSending: true,
            }

            // Add optimistic message to cache
            queryClient.setQueryData<ConversationResponseData>(
                buildQueryKey([CHAT_LIST_NAME, args.conversationSid || sessionId], {
                    includeAuthKeys: true,
                    includeStackId: true,
                }),
                (old) => ({ ...old!, pendingMessage: optimisticMessage })
            )
            try {
                const response = await postMessageApi(args, true)
                const asyncPromise = new Promise<ConversationResponseData>((resolve, reject) => {
                    rejectPromise.current = reject
                    resolvePromise.current = resolve
                })
                if (response.messages?.length ?? 0 > 0) {
                    updateQueryCache(response)
                    return response
                }
                await asyncPromise

                return asyncPromise
            } catch (e) {
                console.error(e)
                setPendingMessageAsFailed(args.conversationSid || '')
            } finally {
                setIsProcessing(false)
            }
        },
        [sessionId, updateQueryCache, setPendingMessageAsFailed]
    )

    return {
        isProcessing,
        postMessageAsync,
    }
}

export function usePostChatMessage() {
    return useMutation(
        async (args: PostMessageArguments) => {
            return await postMessageApi(args, false)
        },
        {
            onMutate: async ({ conversationSid, message }) => {
                if (!conversationSid) {
                    return {}
                }

                const tempId = 'temp-' + Date.now()

                // Create optimistic message
                const optimisticMessage: ChatUserMessage = {
                    id: tempId,
                    type: 'human',
                    content: message,
                    isSending: true,
                }

                if (conversationSid) {
                    // Add optimistic message to cache
                    queryClient.setQueryData<ConversationResponseData>(
                        buildQueryKey([CHAT_LIST_NAME, conversationSid || ''], {
                            includeAuthKeys: true,
                            includeStackId: true,
                        }),
                        (old) => ({ ...old!, pendingMessage: optimisticMessage })
                    )
                }

                return { tempId }
            },
            onSuccess: (data, __) => {
                const { conversation_sid } = data

                // Update cache with actual messages
                queryClient.setQueryData<ConversationResponseData>(
                    buildQueryKey([CHAT_LIST_NAME, conversation_sid], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => {
                        let finalMessages = (old?.messages ?? []).filter(
                            (m) => !data.messages?.find((m2) => m2.id === m.id)
                        )

                        for (const message of data.messages ?? []) {
                            if (message.type === 'remove') {
                                finalMessages = finalMessages.filter((m) => m.id !== message.id)
                            } else {
                                finalMessages.push(message)
                            }
                        }

                        return {
                            ...old!,
                            messages: finalMessages,
                            tool_call_results: [
                                ...(old?.tool_call_results ?? []),
                                ...(data.tool_call_results ?? []),
                            ],
                            interrupt_messages: data.interrupt_messages,
                            pendingMessage: undefined,
                        }
                    }
                )

                invalidateMessages(conversation_sid)
            },
            onError: (_, { conversationSid }, context) => {
                const tempId = context?.tempId

                if (!conversationSid) {
                    return
                }

                // Mark message as failed
                queryClient.setQueryData<ConversationResponseData>(
                    buildQueryKey([CHAT_LIST_NAME, conversationSid], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => ({
                        ...old!,
                        pendingMessage:
                            old?.pendingMessage && old?.pendingMessage?.id === tempId
                                ? { ...old!.pendingMessage, isSending: false, sendingFailed: true }
                                : old?.pendingMessage,
                    })
                )
            },
        }
    )
}

export function cancelPendingMessage(conversationSid: string, messageId: string) {
    queryClient.setQueryData<ConversationResponseData>(
        buildQueryKey([CHAT_LIST_NAME, conversationSid], {
            includeAuthKeys: true,
            includeStackId: true,
        }),
        (old) => ({
            ...old!,
            messages:
                old?.messages?.filter(
                    (msg) =>
                        !(msg.id === messageId && ('isSending' in msg || 'sendingFailed' in msg))
                ) ?? [],
        })
    )
}

export function invalidateMessages(conversationSid: string) {
    return queryClient.invalidateQueries([CHAT_LIST_NAME, getCurrentStackId(), conversationSid])
}
