import React, { useContext } from 'react'

import * as Y from 'yjs'

import { FieldIcon } from 'data/utils/fieldDefinitions'
import { FieldsWidgetAttributeField } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/types'
import { FieldsWidgetType } from 'features/views/LayoutEditor/widgets/FieldsWidget/fieldWidgetTypes'

export type AttributeContextValue<T> = {
    labelPlacement: FieldsWidgetType['attrs']['labelPlacement']
    showFieldIcon: FieldsWidgetType['attrs']['showFieldIcon']
    isEditingLayout: boolean
    isEditingValue: boolean
    value?: T
    toggleEditingValueMode: (enabled: boolean) => void
    field: FieldsWidgetAttributeField
    fieldIcon?: FieldIcon
    replaceValue: (value: Y.AbstractType<any>) => void
    mutateValue: (tr: (value: Y.AbstractType<any>) => Y.AbstractType<any> | undefined) => void
    clearValue: () => Promise<void>
    saveValue: () => Promise<void>
    discardChanges: () => void
    requestEditValueMode: () => void
    isSavingSlow: boolean
}

export const AttributeContext = React.createContext<AttributeContextValue<unknown> | null>(null)

export function useAttributeContext<T>(): AttributeContextValue<T> {
    const context = useContext(AttributeContext) as AttributeContextValue<T>

    if (!context) {
        throw new Error('useFieldsWidgetContext must be used within a FieldsWidgetContextProvider')
    }

    return context
}
