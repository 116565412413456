import { ListViewWidgetType } from './listViewWidgetTypes'

export function convertWidgetToView(widget: ListViewWidgetType): ViewDto {
    const {
        display,
        objectSid,
        stackSid,
        inlineSorting,
        allowDownload,
        enableSpecificEndUserFilters,
        enableEndUserFilters,
        specificEndUserFilters,
        inlineFilters,
        inlineFiltersRecommendedFields,
        title,
        subtitle,
        columnConfigs = [],
        showAllFields = true,
        coverImageAspectRatio = '1:1',
        coverImageFieldApiName,
        tableShowFieldIcons = false,
        tablePinPrimaryColumn = false,
        actionDisplay,
        actionButtons,
        onRecordClick = 'preview',
        pageSize = getDefaultPageSize(widget),
        filters = [],
        sortByFieldApiName,
        sortOrder = 'asc',
        cardTitleSize = 'medium',
        cardLabelStyle,
        cardFooter,
        cardCardEyebrow,
        cardCardTitle,
        cardCardSubtitle,
        cardCardSize = 'medium',
        cardCardStyle = 'border',
        cardPageRows = getDefaultRowsPerPage(widget),
        profileImageFieldApiName,
        boardTitleSize = 'medium',
        boardFieldStyle = 'list',
        boardLabelStyle,
        boardCardFooter,
        boardCardEyebrow,
        boardCardTitle,
        boardCardSubtitle,
        statusColumns,
        statusFieldSid,
    } = widget.attrs

    let viewInlineSorting: ListViewOptions['inlineSorting'] | undefined = undefined
    if (inlineSorting !== 'disabled') {
        viewInlineSorting = inlineSorting
    }

    let viewDisplay: ListViewDisplay = 'tableV2'
    switch (display) {
        case 'table':
            viewDisplay = 'tableV2'
            break
        case 'cards':
            viewDisplay = 'cardV2'
            break
        case 'board':
            viewDisplay = 'boardV2'
            break
    }

    const pageSizeOptions = getPageSizeOptions(widget)
    const effectivePageSize = pageSizeOptions.includes(pageSize)
        ? pageSize
        : getDefaultPageSize(widget)

    const rowsPerPageOptions = getRowsPerPageOptions(widget)
    const effectiveRowsPerPage = rowsPerPageOptions.includes(cardPageRows)
        ? cardPageRows
        : getDefaultRowsPerPage(widget)

    const view: ViewDto = {
        _sid: 'temp_view',
        api_name: 'temp_view',
        object_id: objectSid,
        stack_id: stackSid!,
        options: {
            display: viewDisplay,
            title,
            listHeader: {
                type: 'default',
                title,
                subtitle,
            },
            columns: columnConfigs,
            showAllFields,
            blocks: [],
            document_id: null,
            pageButtons: [],
            profileFields: [],
            tabs: [],
            inlineSorting: viewInlineSorting,
            allowDownload,
            enableEndUserFilters,
            enableSpecificEndUserFilters,
            specificEndUserFilters,
            inlineFilters,
            inlineFiltersRecommendedFields,
            coverImage: coverImageFieldApiName
                ? {
                      id: coverImageFieldApiName,
                      aspectRatio: coverImageAspectRatio,
                  }
                : undefined,
            tableShowFieldIcons,
            tablePinPrimaryColumn,
            actionDisplay,
            actionButtons,
            onRecordClick,
            pageSize: effectivePageSize,
            filters,
            order: sortByFieldApiName
                ? {
                      id: sortByFieldApiName,
                      desc: sortOrder === 'desc',
                  }
                : undefined,
            profileImage: !!profileImageFieldApiName
                ? ({
                      fieldApiName: profileImageFieldApiName,
                  } as any)
                : undefined,
            cardTitleSize,
            cardLabelStyle,
            cardFooter,
            cardCardEyebrow,
            cardCardTitle,
            cardCardSubtitle,
            cardCardSize,
            cardCardStyle,
            cardPageRows: effectiveRowsPerPage,
            boardTitleSize,
            boardFieldStyle,
            boardLabelStyle,
            boardCardFooter,
            boardCardEyebrow,
            boardCardTitle,
            boardCardSubtitle,
            statusColumns,
            statusField: statusFieldSid,
        },
        modified_date: '',
        type: 'list',
        url: '',
        title: '',
    }

    return view
}

export function getPageSizeOptions(_widget: ListViewWidgetType): number[] {
    return [10, 20, 50, 100]
}

export function getDefaultPageSize(widget: ListViewWidgetType): number {
    const options = getPageSizeOptions(widget)

    return options[0]
}

export function getRowsPerPageOptions(_widget: ListViewWidgetType): number[] {
    return [1, 2, 3, 4, 5, 6]
}

export function getDefaultRowsPerPage(widget: ListViewWidgetType): number {
    const options = getRowsPerPageOptions(widget)

    return options[0]
}
