import React, { useCallback } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'

import {
    FieldsWidgetAttributeStyle,
    FieldsWidgetFieldLabelStyles,
    FieldsWidgetFieldValueControlsStyle,
    FieldsWidgetFieldValueStyles,
} from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'

import { useBaseAttributeState } from './hooks/useBaseAttributeState'
import { FieldsWidgetAttributeProps } from './types'
import { ValuePlaceholder } from './ValuePlaceholder'

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Box> &
    FieldsWidgetAttributeProps & {
        isEmpty?: boolean
        showClearValueButton?: boolean
        onClearValue?: () => void
    }

export const BaseAttribute = React.forwardRef<HTMLDivElement, BaseAttributeProps>(
    function BaseAttribute(
        {
            field,
            isEmpty,
            isEditingLayout,
            children,
            className,
            showClearValueButton,
            onClearValue,
            isLoading = false,
            ...props
        },
        ref
    ) {
        const {
            fieldIcon,
            onAttributeClick,
            onValueClear,
            isSaving,
            isEditingValue,
            showFieldIcon,
            labelPlacement,
            onKeyDown,
            attributeRef,
            isEditable,
            isLabelTop,
            isLabelLeft,
        } = useBaseAttributeState({
            onClearValue,
            isLoading,
        })

        const handlePlaceholderClick = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
            e.preventDefault()
        }, [])

        const composedRef = useComposedRefs(ref, attributeRef)

        return (
            <Box
                ref={composedRef}
                flex
                fontFamily="body"
                fontSize="bodyM"
                fontWeight="bodyRegular"
                lineHeight="bodyM"
                color="text"
                column={isLabelTop}
                alignItems="flex-start"
                gap={isLabelLeft ? 'm' : 0}
                trim={!isEditingLayout}
                className={classNames(FieldsWidgetAttributeStyle, className)}
                onClick={onAttributeClick}
                tabIndex={isEditable ? 0 : -1}
                onKeyDown={onKeyDown}
                {...props}
            >
                {(labelPlacement !== 'hide' || showFieldIcon) && (
                    <Box
                        flex
                        center
                        gap="s"
                        shrink
                        trim
                        className={FieldsWidgetFieldLabelStyles.styleFunction({
                            isLabelTop,
                            hasLabel: labelPlacement !== 'hide',
                            hasIcon: showFieldIcon,
                        })}
                    >
                        {!!fieldIcon && <Icon {...fieldIcon} size="l" color="icon" noShrink />}
                        {labelPlacement !== 'hide' && (
                            <Body size="m" color="text" weight="bold" trim>
                                {field.field.label}
                            </Body>
                        )}
                    </Box>
                )}
                <Box pl={isLabelTop && showFieldIcon ? '3xl' : 0} shrink width="full">
                    <Box
                        width="full"
                        className={FieldsWidgetFieldValueStyles.styleFunction({
                            isEditing: isEditingValue,
                            isEditable: isEditable,
                        })}
                    >
                        {isEmpty && !isEditingValue ? (
                            <ValuePlaceholder onClick={handlePlaceholderClick} field={field} />
                        ) : (
                            children
                        )}
                        {isEditingValue && (isSaving || showClearValueButton) && (
                            <Box className={FieldsWidgetFieldValueControlsStyle}>
                                {isSaving && (
                                    <Box p="s">
                                        <Spinner size="xs" />
                                    </Box>
                                )}
                                {!isSaving && showClearValueButton && (
                                    <Button
                                        size="s"
                                        variant="ghost"
                                        startIcon={{ name: 'X' }}
                                        aria-label="Clear value"
                                        onClick={onValueClear}
                                        disabled={!isEditable}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        )
    }
)
