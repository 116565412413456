import { TriggerType } from 'data/hooks/agents/types'

export const TRIGGER_TYPES: Record<string, TriggerType> = {
    app_chatbot: {
        type: 'app_chatbot',
        name: 'App Chatbot',
        description: 'Exposes the agent as a chatbot in the app nav bar',
        icon: 'Bot',
        color: 'sky',
    },
    external_chatbot: {
        type: 'external_chatbot',
        name: 'External Chatbot',
        description: 'Allows the agent to be embedded as a chatbot on an external website',
        icon: 'Globe',
        color: 'purple',
    },
    record_created: {
        type: 'record_created',
        name: 'Record Created',
        description: 'Triggers when a record is created',
        icon: 'FilePlus',
        color: 'teal',
    },
}
