import React, { useCallback } from 'react'

import { useObject } from 'data/hooks/objects'
import { toYType } from 'features/utils/useYjsState'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetAdminControlsComponent } from 'features/views/LayoutEditor/types'
import { ListViewWidgetType } from 'features/views/LayoutEditor/widgets/ListViewWidget/listViewWidgetTypes'
import { convertWidgetToView } from 'features/views/LayoutEditor/widgets/ListViewWidget/utils'
import { InlineFiltersGenericControls } from 'features/views/ListView/Filters/InlineFiltersControls'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'

export const ListViewWidgetActionControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const {
        inlineSorting = 'disabled',
        allowDownload = false,
        allowCreateRecords = true,
        objectSid,
    } = widget.attrs

    const handleInlineSortingChange = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('inlineSorting', value)
            })
        },
        [onChange]
    )

    const handleAllowDownloadChange = useCallback(
        (value: boolean) => {
            onChange((attrs) => {
                attrs.set('allowDownload', value)
            })
        },
        [onChange]
    )

    const handleAllowCreateRecordsChange = useCallback(
        (value: boolean) => {
            onChange((attrs) => {
                attrs.set('allowCreateRecords', value)
            })
        },
        [onChange]
    )

    const fakeView = convertWidgetToView(widget)
    const { object } = useObject(objectSid)
    const fields = object?.fields ?? []

    const handleInlineFiltersChange = useCallback(
        (inlineFilterValues: Partial<ListViewWidgetType['attrs']>) => {
            // Inline filter attributes have the same property names as in the `ListViewOptions` object.
            onChange((attrs) => {
                for (const [key, value] of Object.entries(inlineFilterValues)) {
                    attrs.set(key, toYType(value))
                }
            })
        },
        [onChange]
    )

    if (!object) return null

    return (
        <Box flex column gap="l">
            <LayoutEditorCollapsibleControl
                label="Inline filters"
                startIcon={{ name: 'Settings2' }}
                defaultOpen={false}
            >
                <InlineFiltersGenericControls
                    config={fakeView.options}
                    setConfig={handleInlineFiltersChange as any}
                    fields={fields}
                    object={object}
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Inline sorting"
                startIcon={{ name: 'ArrowUpNarrowWide' }}
                defaultOpen={false}
            >
                <RadioCardGroup
                    value={inlineSorting as string}
                    onValueChange={handleInlineSortingChange}
                >
                    <RadioCard value="disabled" icon={{ name: 'EyeOff' }}>
                        Disabled
                    </RadioCard>
                    <RadioCard value="quick" icon={{ name: 'PanelTopOpen' }}>
                        Enabled
                    </RadioCard>
                </RadioCardGroup>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Export"
                startIcon={{ name: 'Download' }}
                defaultOpen={false}
            >
                <Box flex center justifyContent="space-between">
                    <Box as="label" htmlFor="allowDownload" cursor="pointer">
                        <Body size="s">Allow users to download data</Body>
                    </Box>
                    <Toggle
                        id="allowDownload"
                        checked={allowDownload}
                        onCheckedChange={handleAllowDownloadChange}
                    />
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <Box px="l">
                <Field
                    htmlFor="showAddNewButton"
                    label="Show add new button"
                    rightSlotContent={
                        <Toggle
                            id="showAddNewButton"
                            checked={allowCreateRecords}
                            onCheckedChange={handleAllowCreateRecordsChange}
                        />
                    }
                />
            </Box>
        </Box>
    )
}
