import { useEffect, useMemo, useState } from 'react'

import { useRecords } from 'data/hooks/records'
import { getAvatarFieldApiNames } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const MAX_RECORDS = 100
const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

type UseLookupRecordsInternalOptions = {
    field: FieldDto
    searchQuery?: string
    filterByRecordSids?: string[]
    disabled?: boolean
    filters?: Filter[]
}

function useLookupRecordsInternal(options: UseLookupRecordsInternalOptions) {
    const { field, searchQuery, filterByRecordSids, disabled, filters } = options

    const objectSid = field.link_target_object_id

    const usersObject = useStackerUsersObject()
    const isUsersObject = objectSid === usersObject._sid

    const includedFields: string[] = ['_primary']
    if (isUsersObject) {
        // Include the avatar fields for the users object.
        const avatarApiNames = getAvatarFieldApiNames(usersObject)
        includedFields.push(...avatarApiNames)
    }

    const fetchOptions = {
        includeFields: includedFields,
        pageSize: MAX_RECORDS,
        forcePartials: true,
    }

    const allFilters: Filter[] = [...(filters || [])]
    if (searchQuery) {
        allFilters.push({
            field: { api_name: '_search' },
            options: { value: searchQuery, operator: 'AND' },
        })
    }

    if (filterByRecordSids) {
        allFilters.push({
            field: {
                api_name: '_sid',
            },
            options: {
                value: filterByRecordSids,
                option: 'oneOf',
                operator: 'AND',
            },
        })
    }

    const { data, isError, isFetching } = useRecords({
        objectSid: objectSid!,
        filters: allFilters,
        fetchOptions,
        options: {
            enabled: !!objectSid && !disabled,
        },
    })

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const memoizedRecords = useDeepEqualsMemoValue(data?.records as RecordDto[] | undefined)

    return useMemo(
        () => ({
            records: memoizedRecords,
            isError,
            isFetchingSlow,
            isFetching,
        }),
        [memoizedRecords, isError, isFetchingSlow, isFetching]
    )
}

type UseLookupRecordsOptions = {
    field: FieldDto
    searchQuery?: string
    valueRecordSids?: string[]
    alwaysShowValueRecords?: boolean
    filters?: Filter[]
}

export function useLookupRecords(options: UseLookupRecordsOptions) {
    const { field, searchQuery, valueRecordSids, alwaysShowValueRecords, filters } = options

    const { records, isError, isFetchingSlow, isFetching } = useLookupRecordsInternal({
        field,
        searchQuery,
        filters,
    })

    // Fetch the value records if they are provided.
    // This is for cases where the value records would not be included in the first
    // $MAX_RECORDS records fetched, but we still want to show them.
    const {
        records: valueRecords,
        isError: valueRecordsIsError,
        isFetching: valueRecordsIsFetching,
        isFetchingSlow: valueRecordsIsFetchingSlow,
    } = useLookupRecordsInternal({
        field,
        filterByRecordSids: valueRecordSids,
        searchQuery,
        disabled: !alwaysShowValueRecords,
        filters: filters,
    })

    const effectiveIsError = isError || valueRecordsIsError
    const effectiveIsFetching = isFetching || valueRecordsIsFetching
    const effectiveIsFetchingSlow = isFetchingSlow || valueRecordsIsFetchingSlow

    const mergedRecords = mergeLookupRecords(records, valueRecords)

    return useDeepEqualsMemoValue({
        records: mergedRecords,
        isError: effectiveIsError,
        isFetching: effectiveIsFetching,
        isFetchingSlow: effectiveIsFetchingSlow,
    })
}

function mergeLookupRecords(records?: RecordDto[], valueRecords?: RecordDto[]) {
    if (!records) return undefined

    // Add value records at the top if they are not already in the record list.
    if (valueRecords) {
        const recordSids = records.reduce((set, record) => set.add(record._sid), new Set<string>())
        const valueRecordsToAdd = valueRecords.filter((record) => !recordSids.has(record._sid))

        return [...valueRecordsToAdd, ...records]
    }

    return records
}
