import { useCallback, useMemo, useRef, useState } from 'react'

import getUserLocale from 'get-user-locale'
import moment from 'moment-timezone'

import { FieldsWidgetAttributeField } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/types'
import { formatDate } from 'utils/date/dateUtils'

import { useAttributeContext } from './useAttributeContext'

export function useDateAttributeEditorState(field: FieldsWidgetAttributeField) {
    const {
        value: attributeValue,
        saveValue,
        clearValue,
        replaceValue,
    } = useAttributeContext<string>()

    const showTime = field.field.type === 'datetime'

    const [value, setValue] = useState(attributeValue)
    const valueRef = useRef(value)
    valueRef.current = value

    const timezone = field.field.options?.timezone

    const dateValue = useMemo(() => {
        const { dateValue: parsedValue } = formatDate(value, timezone, showTime)
        if (parsedValue) {
            return parsedValue.toDate()
        }

        return undefined
    }, [timezone, value, showTime])

    const locale = getUserLocale()
    const uses24HrFormat = moment.localeData(locale).longDateFormat('LT').includes('H')

    const dateFormat = useMemo(() => {
        const timeFormat = uses24HrFormat ? 'H:mm' : 'h:mm a'

        if (showTime) {
            return [
                `MMM d, yyyy ${timeFormat}`,
                `dd/MM/yyyy ${timeFormat}`,
                `dd-MM-yyyy ${timeFormat}`,
                `dd.MM.yyyy ${timeFormat}`,
            ]
        }

        return ['MMM d, yyyy', 'dd/MM/yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy']
    }, [showTime, uses24HrFormat])

    const onChange = useCallback(
        (value: Date) => {
            const { dateValue } = formatDate(value, timezone, showTime)
            const formattedDate = showTime
                ? dateValue?.toISOString()
                : dateValue?.format('YYYY-MM-DD')

            setValue(formattedDate)
        },
        [timezone, showTime]
    )

    const onClearValue = useCallback(() => {
        setValue('')
        valueRef.current = ''
        clearValue()
    }, [clearValue])

    const onBlur = useCallback(() => {
        // Use timeout to register inner click events before saving the value.
        setTimeout(() => {
            replaceValue(valueRef.current as any)
            saveValue()
        }, 250)
    }, [replaceValue, saveValue])

    return useMemo(
        () => ({
            showTime,
            dateFormat,
            value: dateValue,
            onChange,
            onClearValue,
            onBlur,
        }),
        [showTime, dateFormat, dateValue, onChange, onClearValue, onBlur]
    )
}
