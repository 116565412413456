import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { usePreviewRecordContext } from 'v2/views/List/PreviewRecord/usePreviewRecordContext'

import { getUrl } from 'app/UrlService'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useBreadcrumbs } from './hooks/useBreadcrumbs'
import { BreadcrumbsContext, BreadcrumbsContextValue } from './hooks/useBreadcrumbsContext'
import { BreadcrumbsHistoryItem, BreadcrumbsItem } from './types'

export type BreadcrumbsContextProviderProps = {}

export const BreadcrumbsContextProvider: React.FC<BreadcrumbsContextProviderProps> = ({
    children,
}) => {
    const history = useHistory<BreadcrumbsHistoryItem>()

    const { previewRecord } = usePreviewRecordContext()

    const navigateTo: BreadcrumbsContextValue['navigateTo'] = useCallback(
        (route) => {
            switch (route.type) {
                case 'detail': {
                    const detailUrl = getUrl(
                        `${route.object.url}/view/${route.recordSid}`,
                        route.stack
                    )

                    const openAs = route.openAs ?? 'full'
                    switch (openAs) {
                        case 'full': {
                            history.push({
                                pathname: detailUrl,
                                state: {
                                    prev: { ...history.location },
                                    type: 'detail',
                                    objectId: route.object._sid,
                                },
                            })
                            break
                        }
                        case 'tab':
                            window.open(detailUrl, '_blank')
                            break
                        case 'preview': {
                            previewRecord({
                                ...route.previewParams,
                                recordId: route.recordSid,
                                objectId: route.object._sid,
                            })
                            break
                        }
                    }

                    break
                }
            }
        },
        [history, previewRecord]
    )

    const breadcrumbs = useBreadcrumbs()

    const prevHistoryItem = history.location.state?.prev
    const prevItem: BreadcrumbsItem | undefined = useDeepEqualsMemoValue(
        !!prevHistoryItem?.state
            ? {
                  path: prevHistoryItem.pathname,
                  label: prevHistoryItem.state.type,
                  type: prevHistoryItem.state.type,
              }
            : undefined
    )

    const value = useMemo(
        () => ({ navigateTo, breadcrumbs, prevItem }),
        [navigateTo, breadcrumbs, prevItem]
    )

    return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>
}
