import React, { useCallback, useState } from 'react'

import { CardBorderIcon } from 'v2/views/List/CardStyleSettings'

import { useObject } from 'data/hooks/objects'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetAdminControlsComponent } from 'features/views/LayoutEditor/types'
import { ListViewWidgetType } from 'features/views/LayoutEditor/widgets/ListViewWidget/listViewWidgetTypes'
import {
    getDefaultPageSize,
    getDefaultRowsPerPage,
    getPageSizeOptions,
    getRowsPerPageOptions,
} from 'features/views/LayoutEditor/widgets/ListViewWidget/utils'
import {
    BoardTitleSizeControlCardStyle,
    BoardTitleSizeControlIconStyle,
} from 'features/views/ListView/BoardView/BoardView.css'
import {
    CardSizeControlCardStyle,
    CardSizeIconInnerContainer,
    CardSizeIconInnerRectangle,
    CardSizeIconOuterContainer,
    CardStyleControlCardStyle,
} from 'features/views/ListView/CardView/CardView.css'

import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'

const DEBOUNCE_RATE = 300 // ms

export const ListViewWidgetStyleControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
    ...props
}) => {
    const { object } = useObject(widget.attrs.objectSid)

    const [localTitle, setLocalTitle] = useState(widget.attrs.title)
    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const handleTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setLocalTitle(value)
            debouncedOnChange((attrs) => {
                attrs.set('title', value)
            })
        },
        [debouncedOnChange]
    )

    const [localSubtitle, setLocalSubtitle] = useState(widget.attrs.subtitle)
    const handleSubtitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setLocalSubtitle(value)
            debouncedOnChange((attrs) => {
                attrs.set('subtitle', value)
            })
        },
        [debouncedOnChange]
    )

    return (
        <Box flex column gap="l">
            <LayoutEditorCollapsibleControl
                label="Header"
                startIcon={{ name: 'LayoutPanelTop' }}
                defaultOpen={false}
            >
                <Box flex column gap="l">
                    <Input
                        label="Title"
                        value={localTitle}
                        onChange={handleTitleChange}
                        placeholder={object?.name}
                    />
                    <Input label="Subtitle" value={localSubtitle} onChange={handleSubtitleChange} />
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            {widget.attrs.display === 'table' && (
                <ListViewTableStyleControls widget={widget} onChange={onChange} {...props} />
            )}
            {widget.attrs.display === 'cards' && (
                <ListViewCardsStyleControls widget={widget} onChange={onChange} {...props} />
            )}
            {widget.attrs.display === 'board' && (
                <ListViewBoardStyleControls widget={widget} onChange={onChange} {...props} />
            )}
        </Box>
    )
}

const ListViewTableStyleControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const {
        tableShowFieldIcons = false,
        tablePinPrimaryColumn = false,
        coverImageAspectRatio = '1:1',
        pageSize = getDefaultPageSize(widget),
    } = widget.attrs

    const pageSizeOptions = getPageSizeOptions(widget)
    const effectivePageSize = pageSizeOptions.includes(pageSize)
        ? pageSize
        : getDefaultPageSize(widget)

    return (
        <>
            <LayoutEditorCollapsibleControl
                label="Table"
                startIcon={{ name: 'Table' }}
                defaultOpen={false}
            >
                <Box flex column gap="l">
                    <Field label="Label icon">
                        <RadioCardGroup
                            value={tableShowFieldIcons.toString()}
                            onValueChange={(value: string) => {
                                onChange((attrs) => {
                                    attrs.set('tableShowFieldIcons', value === 'true')
                                })
                            }}
                        >
                            <RadioCard value="false" icon={{ name: 'EyeOff' }}>
                                Hide
                            </RadioCard>
                            <RadioCard value="true" icon={{ name: 'Eye' }}>
                                Show
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                    <Field label="Thumbnail image ratio">
                        <RadioCardGroup
                            value={coverImageAspectRatio}
                            onValueChange={(value: string) => {
                                onChange((attrs) => {
                                    attrs.set('coverImageAspectRatio', value)
                                })
                            }}
                        >
                            <RadioCard value="1:1" icon={{ name: 'Square' }}>
                                1:1
                            </RadioCard>
                            <RadioCard value="4:3" icon={{ name: 'RectangleHorizontal' }}>
                                4:3
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                    <Field
                        htmlFor="pinFirstColumn"
                        label="Pin first column"
                        rightSlotContent={
                            <Toggle
                                id="pinFirstColumn"
                                checked={tablePinPrimaryColumn}
                                onCheckedChange={(value) => {
                                    onChange((attrs) => {
                                        attrs.set('tablePinPrimaryColumn', value)
                                    })
                                }}
                            />
                        }
                    />
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Pagination"
                startIcon={{ name: 'GalleryHorizontal' }}
                defaultOpen={false}
            >
                <Select
                    label="Records per page"
                    value={effectivePageSize.toString()}
                    placeholder="Select count"
                    onChange={(value: string) => {
                        onChange((attrs) => {
                            attrs.set('pageSize', parseInt(value))
                        })
                    }}
                >
                    {pageSizeOptions.map((option) => (
                        <SelectOption
                            key={option}
                            value={option.toString()}
                            label={option.toString()}
                        />
                    ))}
                </Select>
            </LayoutEditorCollapsibleControl>
        </>
    )
}

const ListViewCardsStyleControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const {
        cardCardSize = 'medium',
        cardCardStyle = 'border',
        cardTitleSize = 'medium',
        cardLabelStyle,
        coverImageFieldApiName,
        cardPageRows = getDefaultRowsPerPage(widget),
    } = widget.attrs

    const rowsPerPageOptions = getRowsPerPageOptions(widget)
    const effectiveRowsPerPage = rowsPerPageOptions.includes(cardPageRows)
        ? cardPageRows
        : getDefaultRowsPerPage(widget)

    return (
        <>
            <LayoutEditorCollapsibleControl
                label="Card"
                startIcon={{ name: 'LayoutGrid' }}
                defaultOpen={false}
            >
                <Box flex column gap="l">
                    <Field label="Card size">
                        <RadioCardGroup
                            value={cardCardSize}
                            onValueChange={(value: string) => {
                                const newValue = value || undefined

                                onChange((attrs) => {
                                    attrs.set('cardCardSize', newValue)
                                })
                            }}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                            }}
                        >
                            <RadioCard
                                value="medium"
                                icon={() => (
                                    <Box className={CardSizeIconOuterContainer}>
                                        <div className={CardSizeIconInnerContainer}>
                                            <div className={CardSizeIconInnerRectangle} />
                                            <div className={CardSizeIconInnerRectangle} />
                                            <div className={CardSizeIconInnerRectangle} />
                                        </div>
                                    </Box>
                                )}
                                className={CardSizeControlCardStyle}
                            >
                                Regular
                            </RadioCard>
                            <RadioCard
                                value="large"
                                icon={() => (
                                    <Box className={CardSizeIconOuterContainer}>
                                        <div className={CardSizeIconInnerContainer}>
                                            <div className={CardSizeIconInnerRectangle} />
                                            <div className={CardSizeIconInnerRectangle} />
                                        </div>
                                    </Box>
                                )}
                                className={CardSizeControlCardStyle}
                            >
                                Large
                            </RadioCard>
                            <RadioCard
                                value="full_width"
                                icon={() => (
                                    <Box className={CardSizeIconOuterContainer}>
                                        <div className={CardSizeIconInnerContainer}>
                                            <div className={CardSizeIconInnerRectangle} />
                                        </div>
                                    </Box>
                                )}
                                className={CardSizeControlCardStyle}
                            >
                                Full-width
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>

                    {!!coverImageFieldApiName && (
                        <Field label="Card style">
                            <RadioCardGroup
                                value={cardCardStyle}
                                onValueChange={(value: string) => {
                                    const newValue = value || undefined

                                    onChange((attrs) => {
                                        attrs.set('cardCardStyle', newValue)
                                    })
                                }}
                            >
                                <RadioCard
                                    value="border"
                                    className={CardStyleControlCardStyle}
                                    icon={() => <CardBorderIcon hasBorder={true} />}
                                >
                                    With border
                                </RadioCard>
                                <RadioCard
                                    value="no_border"
                                    className={CardStyleControlCardStyle}
                                    icon={() => <CardBorderIcon hasBorder={false} />}
                                >
                                    Without border
                                </RadioCard>
                            </RadioCardGroup>
                        </Field>
                    )}
                    <Field label="Title size">
                        <RadioCardGroup
                            value={cardTitleSize}
                            onValueChange={(value: string) => {
                                const newValue = value || undefined

                                onChange((attrs) => {
                                    attrs.set('cardTitleSize', newValue)
                                })
                            }}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                        >
                            <RadioCard
                                value="small"
                                icon={() => (
                                    <Box className={BoardTitleSizeControlIconStyle}>
                                        <Body size="m" weight="bold">
                                            Abc
                                        </Body>
                                    </Box>
                                )}
                                className={BoardTitleSizeControlCardStyle}
                            >
                                Small
                            </RadioCard>
                            <RadioCard
                                value="medium"
                                icon={() => (
                                    <Box className={BoardTitleSizeControlIconStyle}>
                                        <Body size="l" weight="bold">
                                            Abc
                                        </Body>
                                    </Box>
                                )}
                                className={BoardTitleSizeControlCardStyle}
                            >
                                Medium
                            </RadioCard>
                            <RadioCard
                                value="large"
                                icon={() => (
                                    <Box className={BoardTitleSizeControlIconStyle}>
                                        <Body size="xl" weight="bold">
                                            Abc
                                        </Body>
                                    </Box>
                                )}
                                className={BoardTitleSizeControlCardStyle}
                            >
                                Large
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                    <Field label="Label style">
                        <RadioCardGroup
                            value={cardLabelStyle ?? ''}
                            onValueChange={(value: string) => {
                                const newValue = value || undefined

                                onChange((attrs) => {
                                    attrs.set('cardLabelStyle', newValue)
                                })
                            }}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                        >
                            <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                None
                            </RadioCard>
                            <RadioCard value="icon" icon={{ name: 'FlaskConical' }}>
                                Icon
                            </RadioCard>
                            <RadioCard value="text" icon={{ name: 'Text' }}>
                                Text
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Pagination"
                startIcon={{ name: 'GalleryHorizontal' }}
                defaultOpen={false}
            >
                <Select
                    label="Rows per page"
                    value={effectiveRowsPerPage.toString()}
                    placeholder="Select count"
                    onChange={(value: string) => {
                        onChange((attrs) => {
                            attrs.set('cardPageRows', parseInt(value))
                        })
                    }}
                >
                    {rowsPerPageOptions.map((option) => (
                        <SelectOption
                            key={option}
                            value={option.toString()}
                            label={option.toString()}
                        />
                    ))}
                </Select>
            </LayoutEditorCollapsibleControl>
        </>
    )
}

const ListViewBoardStyleControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const { boardTitleSize = 'medium', boardLabelStyle, boardFieldStyle = 'list' } = widget.attrs

    return (
        <>
            <LayoutEditorCollapsibleControl
                label="Card"
                startIcon={{ name: 'LayoutGrid' }}
                defaultOpen={false}
            >
                <Box flex column gap="l">
                    <Field label="Title size">
                        <RadioCardGroup
                            value={boardTitleSize}
                            onValueChange={(value: string) => {
                                const newValue = value || undefined

                                onChange((attrs) => {
                                    attrs.set('boardTitleSize', newValue)
                                })
                            }}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                            }}
                        >
                            <RadioCard
                                value="small"
                                icon={() => (
                                    <Box className={BoardTitleSizeControlIconStyle}>
                                        <Body size="m" weight="bold">
                                            Abc
                                        </Body>
                                    </Box>
                                )}
                                className={BoardTitleSizeControlCardStyle}
                            >
                                Small
                            </RadioCard>
                            <RadioCard
                                value="medium"
                                icon={() => (
                                    <Box className={BoardTitleSizeControlIconStyle}>
                                        <Body size="l" weight="bold">
                                            Abc
                                        </Body>
                                    </Box>
                                )}
                                className={BoardTitleSizeControlCardStyle}
                            >
                                Medium
                            </RadioCard>
                            <RadioCard
                                value="large"
                                icon={() => (
                                    <Box className={BoardTitleSizeControlIconStyle}>
                                        <Body size="xl" weight="bold">
                                            Abc
                                        </Body>
                                    </Box>
                                )}
                                className={BoardTitleSizeControlCardStyle}
                            >
                                Large
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                    <Field label="Label style">
                        <RadioCardGroup
                            value={boardLabelStyle ?? ''}
                            onValueChange={(value: string) => {
                                const newValue = value || undefined

                                onChange((attrs) => {
                                    attrs.set('boardLabelStyle', newValue)
                                })
                            }}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                            }}
                        >
                            <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                None
                            </RadioCard>
                            <RadioCard value="icon" icon={{ name: 'FlaskConical' }}>
                                Icon
                            </RadioCard>
                            <RadioCard value="text" icon={{ name: 'Text' }}>
                                Text
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                    <Field label="Field style">
                        <RadioCardGroup
                            value={boardFieldStyle}
                            onValueChange={(value: string) => {
                                const newValue = value || undefined

                                onChange((attrs) => {
                                    attrs.set('boardFieldStyle', newValue)
                                })
                            }}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            }}
                        >
                            <RadioCard value="list" icon={{ name: 'LayoutList' }}>
                                List
                            </RadioCard>
                            <RadioCard value="tags" icon={{ name: 'Tags' }}>
                                Tags
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                </Box>
            </LayoutEditorCollapsibleControl>
        </>
    )
}
