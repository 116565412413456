import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { FeedWidget, FeedWidgetAdminControls, FeedWidgetType } from './FeedWidget'

export const FeedWidgetMeta: WidgetSchema<FeedWidgetType> = {
    category: 'data',
    label: 'Feed',
    icon: 'MessageSquareText',
    component: FeedWidget,
    adminControlsComponent: FeedWidgetAdminControls,
}
